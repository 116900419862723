import Enum from '@/utils/Enum';

const TenantCompanyTypeEnumList = [
  {
    value: 0,
    name: 'firm',
    desc: '厂商',
  },
  {
    value: 1,
    name: 'service',
    desc: '维修商',
  },
  {
    value: 2,
    name: 'hospitals',
    desc: '医院',
  },
  {
    value: 3,
    name: 'traders',
    desc: '贸易商',
    disabled: true,
  },
];

export default new Enum(TenantCompanyTypeEnumList);
export { TenantCompanyTypeEnumList };
