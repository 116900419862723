
<template>
  <div style='background:#fff;'>
    <content-block class="content_block" title="接口启用：">
      <div class="choiceContianer">
        <div class="content">
          <h6 >发票类型启用</h6>
          <el-checkbox-group :disabled='!isEditing' v-model="JZInfo.choices1" @change="handleTypeChoice" size='mini'>
            <el-row>
              <el-col :span='24'>
                <el-checkbox label="1">增值税普通发票</el-checkbox>
              </el-col>
              <el-col :span='24'>
                <el-checkbox label="2">增值税专用发票</el-checkbox>
              </el-col>
              <el-col :span='24'>
                <el-checkbox label="3">增值税电子普通发票</el-checkbox>
              </el-col>
              <el-col :span='24'>
                <el-checkbox label="4">增值税电子专用发票</el-checkbox>
              </el-col>
            </el-row>
          </el-checkbox-group>
        </div>
        <div class="content">
          <h6 class="text-center">金税接口</h6>
          <el-checkbox-group :disabled='!isEditing' v-model="JZInfo.choices2" @change="handleInterfaceChange" size='mini'>
            <el-row class="text-center">
              <el-col :span='24'>
                <el-checkbox label="1" :disabled="!JZInfo.choices1.includes('1')">启用</el-checkbox>
              </el-col>
              <el-col :span='24'>
                <el-checkbox label="2" :disabled="!JZInfo.choices1.includes('2')">启用</el-checkbox>
              </el-col>
              <el-col :span='24'>
                <el-checkbox label="3" :disabled="!JZInfo.choices1.includes('3')">启用</el-checkbox>
              </el-col>
              <el-col :span='24'>
                <el-checkbox label="4" :disabled="!JZInfo.choices1.includes('4')">启用</el-checkbox>
              </el-col>
            </el-row>
          </el-checkbox-group>
        </div>
        <div class="content">
          <h6 class="text-center">自动确认</h6>
          <el-checkbox-group :disabled='!isEditing' v-model="JZInfo.choices3" size='mini'>
            <el-row class="text-center">
              <el-col :span='24'>
                <el-checkbox label="1" :disabled="!JZInfo.choices1.includes('1')||!JZInfo.choices2.includes('1')">启用</el-checkbox>
              </el-col>
              <el-col :span='24'>
                <el-checkbox label="2" :disabled="!JZInfo.choices1.includes('2')||!JZInfo.choices2.includes('2')">启用</el-checkbox>
              </el-col>
              <el-col :span='24'>
                <el-checkbox label="3" :disabled="!JZInfo.choices1.includes('3')||!JZInfo.choices2.includes('3')">启用</el-checkbox>
              </el-col>
              <el-col :span='24'>
                <el-checkbox label="4" :disabled="!JZInfo.choices1.includes('4')||!JZInfo.choices2.includes('4')">启用</el-checkbox>
              </el-col>
            </el-row>
          </el-checkbox-group>
        </div>
      </div>
    </content-block>
    <content-block title="接口字段默认值：" class="content_block">
      <el-form :model='JZInfo' size='small' :disabled='!isEditing' :inline="true" class="JZForm choiceContianer" label-width='60px'>
        <el-form-item label="收款人：" prop='collectName'>
          <el-input placeholder="请输入收款人" v-if="isEditing" v-model.trim="JZInfo.collectName" maxlength='10' show-word-limit></el-input>
          <span v-else>{{JZInfo.collectName || '--'}}</span>
        </el-form-item>
        <el-form-item label="复核人：" prop='checkName'>
          <el-input placeholder="请输入复核人" v-if="isEditing" v-model.trim="JZInfo.checkName" maxlength='10' show-word-limit></el-input>
          <span v-else>{{JZInfo.checkName || '--'}}</span>
        </el-form-item>
      </el-form>
      <div class="btnsContainer choiceContianer ">
        <el-button type='primary' v-if="isEditing" @click="handleSave">保存</el-button>
        <el-button type='default' v-if="isEditing" @click="handleCancel">取消</el-button>
        <el-button type='primary' @click="isEditing=!isEditing" v-if="!isEditing" v-auth="'saas_jinshui:update'">修改</el-button>
      </div>
    </content-block>
  </div>
</template>

<script>
import { invoiceConfigInfo, updateInvoiceConfig } from '@/api/invoice';
export default {
  name: 'InterfaceConfiguration',
  methods: {
    handleSave () {
      let configList = [
        {id: '1', enableFlag: false, jinshuiFlag: false, autoConfirmFlag: false},
        {id: '2', enableFlag: false, jinshuiFlag: false, autoConfirmFlag: false},
        {id: '3', enableFlag: false, jinshuiFlag: false, autoConfirmFlag: false},
        {id: '4', enableFlag: false, jinshuiFlag: false, autoConfirmFlag: false},
      ];
      let choices1 = this.JZInfo.choices1 || [];
      let choices2 = this.JZInfo.choices2 || [];
      let choices3 = this.JZInfo.choices3 || [];
      configList = configList.map((item, index) => {
        let key = index + 1 + '';
        return {
          id: item.id,
          enableFlag: choices1.includes(key),
          jinshuiFlag: choices2.includes(key),
          autoConfirmFlag: choices3.includes(key),
        };
      });
      let params = {
        checkName: this.JZInfo.checkName,
        collectName: this.JZInfo.collectName,
        configList: configList,
      };
      updateInvoiceConfig(params).then(() => {
        this.isEditing = false;
        this.initPage();
      });
    },
    handleTypeChoice () {
      let choices1 = this.JZInfo.choices1 || [];
      let choices2 = this.JZInfo.choices2 || [];
      let choices3 = this.JZInfo.choices3 || [];
      choices2 = choices2.filter(item => choices1.includes(item));
      this.JZInfo.choices2 = choices2;
      choices3 = choices3.filter(item => choices1.includes(item));
      this.JZInfo.choices3 = choices3;
    },
    handleInterfaceChange () {
      let choices2 = this.JZInfo.choices2 || [];
      let choices3 = this.JZInfo.choices3 || [];
      choices3 = choices3.filter(item => choices2.includes(item));
      this.JZInfo.choices3 = choices3;
    },
    handleCancel () {
      this.isEditing = false;
      this.getInvoiceConfigInfo();
    },
    getInvoiceConfigInfo () {
      invoiceConfigInfo({}).then(res => {
        let currentInfo = res.body;
        let configList = currentInfo.configList;
        let choices1 = [];
        let choices2 = [];
        let choices3 = [];
        for (var i = 0; i < configList.length; i++) {
          let item = configList[i];
          if (item.enableFlag) {
            choices1.push(item.id);
          }
          if (item.jinshuiFlag) {
            choices2.push(item.id);
          }
          if (item.autoConfirmFlag) {
            choices3.push(item.id);
          }
        }
        this.JZInfo = {
          collectName: currentInfo.collectName,
          checkName: currentInfo.checkName || '',
          choices1: choices1,
          choices2: choices2,
          choices3: choices3,
        };
      });
    },
  },
  created () {
    this.getInvoiceConfigInfo();
  },
  data () {
    return {
      isEditing: false,
      JZInfo: {
        collectName: '',
        checkName: '',
        choices1: [],
        choices2: [],
        choices3: [],
      },
    };
  },
};
</script>
<style scoped lang='scss'>
/* @import url(); 引入css类 */
$width: 600px;
$W40: 40px;
/deep/ .choiceContianer{
  display: flex;
  padding: 0 $W40;
  width: $width;
  .content {
    h6 {
      color: #333333;
      margin-bottom: 10px;
      font-size: 14px;
    }
    .el-row{
      border-top: 1px solid #999999;
      .el-col{
        margin-bottom: $W40*0.25;
        height: $W40;
        line-height: $W40;
        border-bottom: 1px solid #efefef;
        .el-checkbox__input.is-checked+.el-checkbox__label{
          color: #409EFF;
        }
        .el-checkbox__input.is-checked .el-checkbox__inner{
          background-color: #409EFF;
          border-color: #409EFF;
        }
      }
    }
    &:nth-child(1){
      width: 60%;
      padding: 0;
      h6{
        margin-left: 24px;
      }
    }
    &:not(:nth-child(1)){
       flex: 1;
    }
  }
}
/deep/ .el-dialog__header{
  border-bottom: 1px solid #efefef;
}
/deep/ .el-dialog__footer{
  border-top: 1px solid #efefef;
}
/deep/ .JZForm {
  margin-top: 0;
  .el-form-item--small.el-form-item {
    display: flex;
    flex: 1;
    .el-form-item__label {
      padding-right:3px;
    }
    .el-form-item__content{
      flex: 1;
    }
  }
}
/deep/ .text-center{
  text-align: center;
}
/deep/ .btnsContainer{
  text-align: right;
  margin-bottom: $W40*0.5;
  width: $width;
  display: inline-block;
}
</style>
