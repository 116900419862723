<template>
<div style="height:100%">
  <content-block title="基础功能">
    <role-tree :menuList="allMenu"
      :menuMap="roleMap">
    </role-tree>
  </content-block>
  <bottom-panel>
    <div>
      <el-button @click="$router.go(-1)">取消</el-button>
      <el-button type="primary" @click="handleSaveResource">保存</el-button>
    </div>
  </bottom-panel>
</div>
</template>
<script>
import _ from 'lodash';
import ProductApi from '@/api/product';
import { flattenTree } from '@/utils/menuTree';
import RoleTree from '@/components/roleTree';
export default {
  name: 'PRODUCT_RESOURCE',
  components: {
    RoleTree,
  },
  props: {
    id: String,
  },
  data () {
    return {
      allMenuCache: null,
      roleMap: {},
      allMenu: [],
    };
  },
  methods: {
    getProductResources () {
      ProductApi.getProductMenuList(this.id).then(res => {
        this.allMenu = _.cloneDeep(this.allMenuCache);
        this.roleMap = flattenTree(this.allMenu).treeMap;
        res.body.forEach(menu => {
          this.roleMap[menu.menuId] && this.$set(this.roleMap[menu.menuId], 'isChecked', true);
        });
      });
    },
    handleSaveResource () {
      let selectedMenus = Object.keys(this.roleMap).filter(r => this.roleMap[r].isChecked).map(r => this.roleMap[r]);
      if (!selectedMenus || !selectedMenus.some(t => t.type === 1)) {
        this.$message.error('请选择要分配的资源权限');
        return false;
      }
      ProductApi.saveProductMenu({productId: this.id, menuIds: selectedMenus.map(t => t.id)}).then(() => {
        this.$message.success('保存成功');
        this.$router.go(-1);
      });
    },
  },
  created () {
    ProductApi.getAllMenu().then((res) => {
      this.allMenuCache = res.body;
      this.getProductResources();
    });
  },
};
</script>
