<template>
  <el-dialog
    :title="currentStatus === 1? '提交失败': '同步失败'"
    :visible.sync="isShow"
    width="400px"
    :append-to-body='true'
    :destroyOnClose='true'
    :close-on-click-modal='false'
  >
    <div class="content content_block">
      <div v-if="currentStatus === 1">
        <h4>失败原因：{{currentMessage}}</h4>
        <div class="reasonContent">
          <p>提交失败，请检查：</p>
          <p>1、检查开票机网络链接是否正常。</p>
          <p>2、尝试重启开票机并重试。</p>
          <p>3、稍后重试或联系管理员。</p>
        </div>
      </div>
      <div v-else>
        <div class="reasonContent">
          <p>未获取到开票结果，请检查：</p>
          <p>1、确认金税系统是否已开票完成。</p>
          <p>2、检查开票机网络链接是否正常。</p>
          <p>3、尝试重启开票机并重试。</p>
          <p>4、稍后重试或联系管理员。</p>
        </div>
      </div>
    </div>
    <span slot="footer" class="dialog-footer " style="text-align:right;" >
      <el-button @click="handleManualInvoicing"  type='default' size="mini">手工开票</el-button>
      <el-button @click="isShow=false" type='default'  size="mini" >关闭</el-button>
    </span>
  </el-dialog>
</template>

<script>
import {transportInvoiceStatus} from '@/api/invoice';
export default {
  name: 'SubmitResult',
  methods: {
    handleManualInvoicing () {
      let settings = {};
      Object.assign(settings, { // 合并对象
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
        dangerouslyUseHTMLString: true, // 允许确认框内容为html格式
      });
      this.$confirm('<p>该申请将调整为手工开票流程，确定吗？</p>', settings).then(() => {
        transportInvoiceStatus({id: this.currentInvoiceId, method: 'MANUAL'}).then(() => {
          this.isShow = false;
          if (this.isViewing) {
            this.$emit('handleChangeInvoicing');
          } else {
            this.$emit('handleManualInvoicing');
          }
        });
      });
    },
  },
  data () {
    return {
      currentMessage: '',
      isShow: false,
      currentStatus: 1,
      isViewing: false,
      currentInvoiceId: '',
    };
  },
};
</script>
<style scoped lang='scss'>
/* @import url(); 引入css类 */

</style>
