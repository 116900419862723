import server from '../request';

export default {
  getProductList ({name, pageSize, pageNum, orderByProperty, orderByType, releaseStatus}) {
    orderByProperty = orderByProperty || 'lastUpdateTime';
    orderByType = orderByType || 'DESC';
    return server.post('/xiaozi-saas/wireless/product/page', {
      name,
      pageSize,
      pageNum,
      releaseStatus,
      orderBy: [
        {
          orderByProperty,
          orderByType,
        },
      ],
    });
  },

  getAllMenu () {
    return server.post('/xiaozi-saas/wireless/menu/menuTree');
  },

  getProductMenuList (productId) {
    return server.post('/xiaozi-saas/wireless/menu/productMenuList', {productId});
  },

  getProductMenuTree (productId) {
    return server.post('/xiaozi-saas/wireless/menu/productMenuTree', {productId});
  },

  saveProductMenu ({productId, menuIds}) {
    return server.post('/xiaozi-saas/wireless/menu/saveProductMenuRelation', {
      productId,
      menuIds,
    });
  },

  getDatascopeList ({dataFunctionName, dataTagName, orderByProperty, orderByType, pageNum, pageSize, productId, findDataRuleFlag = true}) {
    orderByProperty = orderByProperty || 'lastUpdateTime';
    orderByType = orderByType || 'DESC';
    return server.post('/xiaozi-saas/wireless/dataPermission/dataTagPage', {findDataRuleFlag, dataFunctionName, dataTagName, orderByProperty, orderByType, pageNum, pageSize, productId});
  },

  switchDatascopeFlag ({id, enableFlag}) {
    return server.post('/xiaozi-saas/wireless/dataPermission/dataTagEnableOrDisable', {id, enableFlag});
  },

  getDatascopeRuleList (dataTagId) {
    return server.post('/xiaozi-saas/wireless/dataPermission/dataRuleList', {dataTagId});
  },

  updateDatascopeRule (param) {
    return server.post('/xiaozi-saas/wireless/dataPermission/dataRuleBatchUpdate', param);
  },

  getOrgTree (productId) {
    return server.post('/xiaozi-saas/wireless/department/treeList', {productId});
  },

  addOrg ({code, name, pid, productId}) {
    return server.post('/xiaozi-saas/wireless/department/add', {code, name, pid, productId});
  },

  deleteOrg ({id, productId}) {
    return server.post('/xiaozi-saas/wireless/department/delById', {id, productId});
  },

  updateOrg ({code, id, name, pid, productId, level}) {
    return server.post('/xiaozi-saas/wireless/department/edit', {code, id, name, pid, productId, level});
  },

  checkLevel ({id, productId}) {
    return server.post('/xiaozi-saas/wireless/department/check', {id, productId});
  },

  getRoleList ({name, productId, pageNum, pageSize, orderByProperty, orderByType}) {
    orderByProperty = orderByProperty || 'lastUpdateTime';
    orderByType = orderByType || 'DESC';
    return server.post('/xiaozi-saas/wireless/role/page', {name, productId, pageNum, pageSize, orderByProperty, orderByType});
  },

  addRole ({name, description, productId, menuReqDTOList}) {
    return server.post('/xiaozi-saas/wireless/role/add', {name, description, productId, menuReqDTOList});
  },

  updateRole ({id, name, description, productId, menuReqDTOList}) {
    return server.post('/xiaozi-saas/wireless/role/update', {id, name, description, productId, menuReqDTOList});
  },

  deleteRole (id) {
    return server.post('/xiaozi-saas/wireless/role/delete', {id});
  },

  getRoleDetail ({id, findRelMenuListFlag = true, findRelMenuTreeFlag = true}) {
    return server.post('/xiaozi-saas/wireless/role/detail', {id, findRelMenuListFlag, findRelMenuTreeFlag});
  },
  // 更新产品发布状态
  updateReleaseStatus (params) {
    return server.post('/xiaozi-saas/wireless/product/updateReleaseStatus', params);
  },
};
