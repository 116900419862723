<template>
    <div class="role_tree">
        <tree-node
            v-for="node in menuList"
            :roleMap="menuMap"
            :key="node.id"
            :readonly="readonly"
            :nodeData="node"
            :treeNodeProps="{
                key: 'id',
                name: 'name',
                children: 'children',
                parentId:'pid'
            }"
        />
    </div>
</template>

<script>
import TreeNode from './treeNode';
export default {
  name: 'role_tree',
  components: {
    TreeNode,
  },
  props: {
    menuList: {
      type: Array,
      default: () => [],
    },
    menuMap: {
      type: Object,
      default: () => {},
    },
    readonly: {
      type: Boolean,
      default: false,
    },
  },
};
</script>

<style lang="scss" scoped>
.role_tree {
    >div{
        border-left: 1px solid rgba(36, 40, 36, 0.1);
        &:first-child {
          border-top: 1px solid rgba(36, 40, 36, 0.1);
        }
    }
}
</style>
