<template>
<div class="content_block">
  <el-tabs v-model="activeName" @tab-click="handleTabClick">
    <el-tab-pane label="部门云配置" name="first">
      <vxe-table
        ref="orgTree"
        resizable
        border="full"
        size="small"
        header-align="center"
        highlight-current-row
        highlight-hover-row
        highlight-current-column
        highlight-hover-column
        row-id="id"
        :tree-config="{children: 'children', expandAll: true}"
        :data="orgTree">
        <vxe-column field="name" title="部门名称" tree-node></vxe-column>
        <vxe-column field="code" title="部门编码"></vxe-column>
        <vxe-column field="level" title="部门层级"></vxe-column>
        <vxe-column field="date" title="操作">
          <template #default="{ row }">
            <span class="cursor-pointer text-primary margin-left" @click="handleOrgAction(row,'EDIT')">修改部门</span>
            <span class="cursor-pointer text-primary margin-left" @click="handleOrgAction(row,'DELETE')">删除部门</span>
            <span class="cursor-pointer text-primary margin-left" @click="handleOrgAction(row,'ADD')">新增下级部门</span>
          </template>
        </vxe-column>
      </vxe-table>
    </el-tab-pane>
    <el-tab-pane label="角色云配置" name="second" lazy>
      <table-with-pagination :tableData="roleList"
        :showRightToolbar="false"
        :leftToolbar="tableButtons"
        :columnList="roleColumnList"
        :pagination.sync="pagination"
        :sortInfo.sync="updateSortInfo"
        @size-change="getRoleList"
        @current-change="getRoleList"
        @sort-change="getRoleList"
      />
    </el-tab-pane>
  </el-tabs>
  <el-dialog :title="orgModel.id? '修改部门' :'新增下级部门'" width="600px" append-to-body :visible.sync="orgDialogVisible" v-if="orgDialogVisible">
    <el-form label-width="100px" ref="orgForm" :rules="orgModelRules" :model="orgModel">
      <el-form-item label="部门名称" prop="name">
        <el-input v-model.trim="orgModel.name"></el-input>
      </el-form-item>
      <el-form-item label="上级部门" prop="pid">
        <department-selector v-model="orgModel.pid" :name.sync="orgModel.pName"  :product-id="id" :clearable="false" :readonly="!orgModel.id" />
      </el-form-item>
    </el-form>
    <span slot="footer">
      <el-button @click="orgDialogVisible=false">取消</el-button>
      <el-button type="primary" @click="handleAddOrEditOrg">保存</el-button>
    </span>
  </el-dialog>
  <el-drawer  :title="roleDialogReadonly?'角色权限详情':(roleDetailModel&&roleDetailModel.id?'编辑角色':'新增角色')"
    :visible.sync="roleDialogVisible"
    destroy-on-close
    size="800px"
  >
    <div class="role_drawer">
      <el-form class="content_block" label-width="100px" label-position="left" ref="roleDetailForm" :model="roleDetailModel" :rules="roleDetailModelRule">
        <el-form-item label="角色名称" prop="name">
          <el-input placeholder="请输入角色名称" :readonly="roleDialogReadonly" v-model.trim="roleDetailModel.name"></el-input>
        </el-form-item>
        <el-form-item label="角色描述" prop="description">
          <el-input type="textarea" placeholder="请输入角色描述" :readonly="roleDialogReadonly" :maxlength="50" show-word-limit v-model.trim="roleDetailModel.description"></el-input>
        </el-form-item>
      </el-form>
      <div class="role_drawer_table content_block">
        <vxe-table
          height="100%"
          ref="roleTree"
          resizable
          border="full"
          size="small"
          header-align="center"
          highlight-current-row
          highlight-hover-row
          highlight-current-column
          highlight-hover-column
          row-id="id"
          :tree-config="{children: 'children',expandAll: true}"
          :data="roleDetailTree">
          <vxe-column tree-node>
             <template #header>
              <el-checkbox :disabled="roleDialogReadonly" v-model="isCheckedAll">菜单资源</el-checkbox>
             </template>
            <template #default="{ row }">
              <el-checkbox :disabled="roleDialogReadonly" v-model="row.isChecked" @change="handleRoleMenuChanged(row,$event)">{{row.name}}</el-checkbox>
            </template>
          </vxe-column>
          <vxe-column field="date" title="数据权限">
            <template #default="{ row }">
              <el-radio-group v-model="row.dataTagId" :disabled="roleDialogReadonly" @change="handleDatascopeChange(row,$event)">
                <el-radio :label="i.id" v-for="i in row.dataTagRespDTOList" :key="i.id">{{i.name}}</el-radio>
              </el-radio-group>
            </template>
          </vxe-column>
        </vxe-table>
      </div>
      <div class="role_drawer_footer">
        <el-button @click="roleDialogVisible=false">取消</el-button>
        <el-button v-if="!roleDialogReadonly" type="primary" @click="handleAddOrUpdateRole">保存</el-button>
      </div>
    </div>
  </el-drawer>
</div>
</template>
<script>
import ProductApi from '@/api/product';
import { flattenTree } from '@/utils/menuTree';
import DepartmentSelector from '@/components/common/departmentSelector';
import { TablePaginationMixin } from '@/components/common/TableWithPagination';

export default {
  name: 'PRODUCT_ORG_ROLE',
  components: {
    DepartmentSelector,
  },
  mixins: [
    TablePaginationMixin,
  ],
  props: {
    id: String,
  },
  data () {
    return {
      activeName: 'first',
      orgTree: [],
      orgModel: {
        id: '',
        name: '',
        level: 0,
        code: '',
        pid: '',
        pName: '',
        productId: '',
      },
      orgModelRules: {
        name: [
          { required: true, message: '请填写部门名称', trigger: 'blur' },
          { max: 10, message: '部门名称长度不能超过10', trigger: 'blur' },
        ],
      },
      orgDialogVisible: false,
      roleList: [],
      roleColumnList: [
        {
          tooltip: true,
          label: '角色名称',
          minWidth: '100px',
          prop: 'name',
        },
        {
          tooltip: true,
          label: '角色详情',
          minWidth: '100px',
          prop: 'description',
        },
        {
          tooltip: true,
          label: '操作',
          minWidth: '100px',
          render: (h, {row})=>{
            let actions = [];
            actions.push(<span class='cursor-pointer text-primary margin-left' onClick={this.handleRoleAction.bind(this, row, 'DETAIL')}>查看</span>);
            actions.push(<span class='cursor-pointer text-primary margin-left' onClick={this.handleRoleAction.bind(this, row, 'EDIT')}>编辑</span>);
            actions.push(<span class='cursor-pointer text-primary margin-left' onClick={this.handleRoleAction.bind(this, row, 'DELETE')}>删除</span>);
            return <div>{...actions}</div>;
          },
        },
      ],
      tableButtons: [
        {
          name: '新增角色',
          status: 'primary',
          size: 'small',
          code: 'AddBrand',
          icon: 'vxe-icon--plus',
          click: ()=>{
            this.roleDetailModel = {
              id: '',
              name: '',
              description: '',
              menuReqDTOList: [],
            };
            this.roleDialogVisible = true;
            this.roleDialogReadonly = false;
            ProductApi.getProductMenuTree(this.id).then(res =>{
              this.roleMenuMap = flattenTree(res.body || []).treeMap;
              this.roleDetailTree = res.body || [];
              this.$nextTick(()=>{
                this.$refs.roleTree.setAllTreeExpand(true);
              });
            });
          },
        },
      ],
      roleDialogVisible: false,
      roleDetailModel: {
        id: '',
        name: '',
        description: '',
        menuReqDTOList: [],
      },
      roleDetailTree: [],
      roleMenuMap: {},
      roleDetailModelRule: {
        name: [
          { required: true, message: '请输入角色名称', trigger: 'blur' },
          { max: 10, message: '角色名称长度10个字符', trigger: 'blur' },
        ],
        description: [
          { max: 50, message: '角色描述最多50个字符', trigger: 'blur' },
        ],
      },
      roleDialogReadonly: false,
    };
  },
  methods: {
    handleTabClick () {
      if (this.activeName === 'second' && (!this.roleList || !this.roleList[0])) {
        this.getRoleList();
      }
    },
    handleDatascopeChange (row) {
      if (!row.isChecked) {
        this.$set(row, 'isChecked', true);
        this.handleRoleMenuChanged(row, true);
      }
    },
    handleRoleMenuChanged (row, val) {
      let setAfterNodeChecked = (node, isChecked) => {
        this.$set(node, 'isChecked', isChecked);
        if (!isChecked) {
          this.$set(node, 'dataTagId', null);
        }
        node.children && node.children.forEach(n => {
          setAfterNodeChecked(n, isChecked);
        });
      };
      let setPreNodeChecked = (node, isChecked) => {
        let parentNode = this.roleMenuMap[node.pid];
        if (!parentNode) return;
        if (isChecked) {
          // 若选中，需要递归往上确认父节点是否需要选中
          this.$set(parentNode, 'isChecked', isChecked);
          setPreNodeChecked(parentNode, isChecked);
        } else {
          // 若取消选中，需要判断父节点是否选中，进行取消动作
          if (parentNode.isChecked && parentNode.children.every(t => !t.isChecked)) {
            this.$set(parentNode, 'isChecked', isChecked);
            setPreNodeChecked(parentNode, isChecked);
          }
        }
      };
      setAfterNodeChecked(row, val);
      // 按钮操作权限不影响菜单资源选中
      if (row.type !== 3) {
        setPreNodeChecked(row, val);
      }
    },
    handleOrgAction (row, action) {
      if (action === 'DELETE') {
        this.$confirm('删除部门时会同时删除其下级部门，确认删除吗？', '删除部门').then(() => {
          this.id && ProductApi.deleteOrg({id: row.id, productId: this.id}).then(() =>{
            this.getOrgTree();
          });
        });
      } else if (action === 'EDIT') {
        this.orgModel = {...row};
        this.orgModel.productId = this.id;
        this.orgModel.oldPid = row.pid;
        this.orgDialogVisible = true;
        this.$nextTick(()=>{
          this.$refs.orgForm && this.$refs.orgForm.clearValidate();
        });
      } else if (action === 'ADD') {
        this.orgModel = {
          id: '',
          name: '',
          level: 0,
          code: '',
          pid: row.id,
          pName: row.name,
          productId: this.id,
        };
        this.orgDialogVisible = true;
        this.$nextTick(()=>{
          this.$refs.orgForm && this.$refs.orgForm.clearValidate();
        });
      }
    },

    handleAddOrEditOrg () {
      this.$refs.orgForm.validate(valid => {
        if (valid) {
          if (!this.orgModel.id) {
            ProductApi.addOrg({...this.orgModel}).then(()=>{
              this.orgDialogVisible = false;
              this.getOrgTree();
            });
          } else {
            ProductApi.updateOrg({...this.orgModel}).then(()=>{
              this.orgDialogVisible = false;
              this.getOrgTree();
            });
          }
        }
      });
    },
    getOrgTree () {
      this.id && ProductApi.getOrgTree(this.id).then(res => {
        this.orgTree = res.body;
        this.$nextTick(()=>{
          this.$refs.orgTree.setAllTreeExpand(true);
        });
      });
    },

    handleRoleAction (row, action) {
      if (action === 'DELETE') {
        this.$confirm('确认删除吗？', '删除角色').then(() => {
          ProductApi.deleteRole(row.id).then(this.getRoleList);
        });
      } else if (action === 'EDIT' || action === 'DETAIL') {
        Promise.all([
          ProductApi.getRoleDetail({id: row.id}),
          ProductApi.getProductMenuTree(this.id),
        ]).then(results =>{
          let { id, name, description, menuRespDTOList } = results[0].body;
          this.roleDetailModel = { id, name, description };
          let roleMenuMap = flattenTree(results[1].body || []).treeMap;
          menuRespDTOList.forEach(r =>{
            let menu = roleMenuMap[r.id];
            if (menu) {
              menu.isChecked = true;
              menu.dataTagId = r.dataTagId;
            }
          });
          this.roleMenuMap = roleMenuMap;
          this.roleDetailTree = results[1].body || [];
          this.roleDialogVisible = true;
          this.roleDialogReadonly = action === 'DETAIL';
        });
      }
    },

    handleAddOrUpdateRole () {
      this.$refs.roleDetailForm.validate(valid=>{
        if (valid) {
          let selectedMenus = [];
          Object.keys(this.roleMenuMap).forEach(t=>{
            if (this.roleMenuMap[t].isChecked === true) {
              selectedMenus.push(this.roleMenuMap[t]);
            }
          });
          if (!selectedMenus || !selectedMenus[0]) {
            this.$message.error('请选择菜单资源');
            return;
          }

          if (selectedMenus.some(t=>t.dataTagRespDTOList && t.dataTagRespDTOList[0] && !t.dataTagId)) {
            this.$message.error('请选择菜单资源对应的数据权限');
            return;
          }

          let action = this.roleDetailModel.id ? ProductApi.updateRole({...this.roleDetailModel, menuReqDTOList: selectedMenus}) : ProductApi.addRole({...this.roleDetailModel, menuReqDTOList: selectedMenus, productId: this.id});
          action.then(()=>{
            this.roleDialogVisible = false;
            this.getRoleList();
          });
        }
      });
    },

    getRoleList () {
      ProductApi.getRoleList({productId: this.$route.query.id, ...this.pagination, ...this.updateSortInfo, pageNum: this.pagination.currentPage}).then(res =>{
        this.roleList = res.body.list;
        this.pagination.total = res.body.total;
      });
    },
  },
  computed: {
    isCheckedAll: {
      get: function () {
        return Object.keys(this.roleMenuMap).length > 0 && Object.keys(this.roleMenuMap).length === Object.keys(this.roleMenuMap).filter(t=>this.roleMenuMap[t].isChecked).length;
      },
      set: function (val) {
        Object.keys(this.roleMenuMap).forEach(t=>{
          this.$set(this.roleMenuMap[t], 'isChecked', val);
        });
      },
    },
  },
  created () {
    this.getOrgTree();
  },
};
</script>

<style lang="scss" scoped>
.role_drawer {
  height: 100%;
  display: flex;
  flex-direction: column;

  &>.role_drawer_table{
    flex: 1;
  }

  &>.role_drawer_footer{
    height: 60px;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    padding-right: 24px;
  }
}
</style>
