import Enum from '@/utils/Enum';

const TenantSourceEnumList = [
  {
    value: 0,
    name: 'onLine',
    desc: '线上',
  },
  {
    value: 1,
    name: 'offLine',
    desc: '线下',
  },
];

export default new Enum(TenantSourceEnumList);
export { TenantSourceEnumList };
