
import server from '@/request';

// 发票申请确认
const confirmInvoice = (params)=> {
  return server.post('/xiaozi-saas/wireless/invoice/confirm', params);
};

// 发票申请开票
const drawInvoice = (params)=> {
  return server.post('/xiaozi-saas/wireless/invoice/draw', params);
};

// 发票申请详情
const detailInvoice = (params)=> {
  return server.post('/xiaozi-saas/wireless/invoice/queryDetail', params);
};

// 发票申请列表
const getInvoiceLists = (params)=> {
  return server.post('/xiaozi-saas/wireless/invoice/queryPage', params);
};

// 邮寄发票
const postInvoice = (params)=> {
  return server.post('/xiaozi-saas/wireless/invoice/postInvoice', params);
};

// 重新提交金税系统
const reSubmitJinShui = (params)=> {
  return server.post('/xiaozi-saas/wireless/invoice/reSubmitJinShui', params);
};

// 发票配置信息
const invoiceConfigInfo = (params)=> {
  return server.post('/xiaozi-saas/wireless/invoice/invoiceConfigInfo', params);
};

// 同步金税开票接口
const synJinShui = (params)=> {
  return server.post('/xiaozi-saas/wireless/invoice/synJinShui', params);
};

// 发票配置信息更新
const updateInvoiceConfig = (params)=> {
  return server.post('/xiaozi-saas/wireless/invoice/updateInvoiceConfig', params);
};

// 同步状态转为手动开发票
const transportInvoiceStatus = (params)=> {
  return server.post('/xiaozi-saas/wireless/invoice/changeMethod', params);
};

// 发票驳回
const rejectInvoice = (params)=> {
  return server.post('/xiaozi-saas/wireless/invoice/reject', params);
};

export {
  confirmInvoice,
  drawInvoice,
  detailInvoice,
  getInvoiceLists,
  postInvoice,
  invoiceConfigInfo,
  reSubmitJinShui,
  synJinShui,
  updateInvoiceConfig,
  transportInvoiceStatus,
  rejectInvoice,
};
