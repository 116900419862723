<template>
  <content-block>
    <content-block title="申请信息">
      <el-form label-width="150px" ref="tenantForm" :model="tenantForm" :rules="tenantFormRules">
        <el-form-item label="公司名称" prop="companyName">
          <el-autocomplete ref="companySearch"
            class="w-full"
            value-key="Name"
            :debounce="500"
            v-model="tenantForm.companyName"
            :fetch-suggestions="queryCompanyAsync"
            placeholder="请填写试用公司名称"
            :disabled="applyFormReadony"
            @select="handleCompanySelect"
          >
            <el-button slot="append"  :disabled="applyFormReadony" icon="el-icon-search" @click="handleSearchCompany"></el-button>
          </el-autocomplete>
        </el-form-item>
        <el-form-item label="统一社会信用代码" prop="certCode">
          <el-input v-model.trim="tenantForm.certCode" placeholder="请填写统一社会信用代码" :readonly="applyFormReadony"></el-input>
        </el-form-item>
        <el-form-item label="公司规模" prop="scale">
          <el-select v-model="tenantForm.scale" :disabled="applyFormReadony" clearable  placeholder="请选择公司规模">
            <el-option value="10人以下"></el-option>
            <el-option value="10~50人"></el-option>
            <el-option value="50~100人"></el-option>
            <el-option value="100~500人"></el-option>
            <el-option value="500人以上"></el-option>
          </el-select>
        </el-form-item>
         <el-form-item label="公司类型" prop="type">
          <el-select v-model="tenantForm.type" :disabled="applyFormReadony" clearable placeholder="请选择公司类型">
            <el-option v-for="i in tenantCompanyTypeEnumList" :key="i.name" :label="i.desc" :value="i.name"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="联系人" prop="applicantName" >
          <el-input v-model.trim="tenantForm.applicantName" :readonly="applyFormReadony" placeholder="请填写联系人姓名"></el-input>
        </el-form-item>
         <el-form-item label="联系人手机号"  prop="applicantPhoneNumber">
          <el-input  v-model.trim="tenantForm.applicantPhoneNumber" :readonly="applyFormReadony" placeholder="请填写联系人手机号" @change="val=>applyForm.adminAccount=val"></el-input>
        </el-form-item>
         <el-form-item label="联系人职位" prop="applicantPosition">
          <el-input v-model="tenantForm.applicantPosition" :readonly="applyFormReadony" placeholder="请填写联系人职位"></el-input>
        </el-form-item>
         <el-form-item label="试用产品"  prop="trialProductId">
          <product-selector v-model="tenantForm.trialProductId" :name.sync="tenantForm.trialProductName" placeholder="请选择试用产品" :readonly="applyFormReadony"></product-selector>
        </el-form-item>
        <el-form-item label="推荐人" prop="referrerName" v-if="isDisabled">
          <el-input v-model.trim="tenantForm.referrerName"  disabled  placeholder="请输入推荐人"></el-input>
        </el-form-item>
        <el-form-item label="推荐人" prop="referrerName" v-else>
          <el-input v-model.trim="tenantForm.referrerName"  readonly @click.native="selectorVisible=!applyFormReadony" placeholder="请选择推荐人"></el-input>
        </el-form-item>
         <el-form-item v-if="isUsing" label="试用周期">
          {{tenantForm.trialPeriod}}天
        </el-form-item>
        <el-form-item v-if="tenantForm.trialStatus==='waitActive'" label="首次开通日期">
          {{tenantForm.firstOpenTime | dateFormat}}
        </el-form-item>
        <el-form-item v-if="tenantForm.trialStatus==='waitActive'" label="试用到期日期">
          {{tenantForm.trialActiveTime | dateFormat}}
        </el-form-item>
        <el-form-item v-if="isCreating">
          <el-button @click="$router.go(-1)">取消</el-button>
          <el-button type="primary" @click="handleAddTenant">提交</el-button>
        </el-form-item>
      </el-form>
    </content-block>
    <content-block title="处理信息" v-if="!isCreating">
      <el-form v-if="isUsing" ref="closeForm" :model="closeForm" label-width="100px">
          <el-form-item label="关闭原因" prop="closeReason" :rules="[{required:true, message:'关闭原因必填'},{max:50, message:'关闭原因字符长度50字符'}]">
            <el-input type="textarea" v-model.trim="closeForm.closeReason"  show-word-limit placeholder="请输入关闭原因，字符长度50字符"></el-input>
          </el-form-item>
          <el-form-item>
            <el-button @click="$router.go(-1)">取消</el-button>
            <el-button type="danger" @click="handleClose">关闭试用</el-button>
          </el-form-item>
      </el-form>
      <el-form v-else ref="applyForm" label-width="120px" :model="applyForm" :rules="applyFormRules">
        <el-form-item label="开通类型">
          <el-select v-model="applyForm.openType" disabled>
            <el-option label="试用申请" value="trial"></el-option>
            <el-option label="激活申请" value="activate"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="试用周期">
          <el-select v-model="applyForm.trialPeriod" :disabled="isWaitingActive">
            <el-option label="7天" :value="7"></el-option>
            <el-option label="14天" :value="14"></el-option>
            <el-option label="30天" :value="30"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="超管账号" prop="adminAccount">
          <el-input v-model.trim="applyForm.adminAccount" readonly name='adminAccount' ></el-input>
        </el-form-item>
        <el-form-item label="超管密码" prop="adminPassword">
          <el-input type="password" v-model.trim="applyForm.adminPassword" name="adminPassword" placeholder="请输入超管密码" :readonly="isWaitingActive"></el-input>
        </el-form-item>
        <el-form-item>
          <el-button @click="$router.go(-1)">取消</el-button>
          <el-button type="danger" @click="dialogVisible = true">驳回申请</el-button>
          <el-button type="primary" @click="handleApproveTenant">通过申请</el-button>
        </el-form-item>
      </el-form>
    </content-block>
    <el-dialog :visible.sync="dialogVisible" width="500px" title="驳回申请" :close-on-click-modal="false">
      <el-form label-width="100px" ref="rejectForm" :model="rejectForm">
        <el-form-item label="驳回原因" prop="rejectReason" :rules="{required:true, message:'驳回原因必填'}">
          <el-input type="textarea" v-model.trim="rejectForm.rejectReason" :maxlength="50" show-word-limit></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer">
        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="handleReject">确 定</el-button>
      </span>
    </el-dialog>
    <account-selector v-model.trim="selectorVisible"
      :maxCount="1"
      title="选择推荐人"
      :defaultSelected="defaultSelectedUser"
      @confirm="handleConfirm"/>
  </content-block>
</template>

<script>
import TenantApi from '@/api/tenant';
import * as dayjs from 'dayjs';
import ProductSelector from '@/components/productSelector';
import AccountSelector from '@/components/accountSelector';
import { TenantCompanyTypeEnumList as tenantCompanyTypeEnumList } from '@/model/TenantCompanyTypeEnum';
export default {
  name: 'TENANT_DETAIL',
  components: {
    ProductSelector,
    AccountSelector,
  },
  props: {
    // 0: 新增，1：试用审核，2：激活审核，3：关闭
    status: {
      type: Number,
      default: 0,
    },
    id: {
      type: String,
    },
  },
  computed: {
    applyFormReadony () {
      return !this.isCreating && !this.isWaiting;
    },
    isCreating () {
      return !this.tenantForm?.id;
    },
    isWaiting () {
      return !!this.tenantForm?.id && this.tenantForm?.trialStatus === 'wait';
    },
    isWaitingActive () {
      return !!this.tenantForm?.id && this.tenantForm?.trialStatus === 'waitActive';
    },
    isUsing () {
      // 是否正在使用中
      return !!this.tenantForm?.id && this.tenantForm?.trialStatus === 'run';
    },
    defaultSelectedUser () {
      return this.tenantForm.referrerId ? [{id: this.tenantForm.referrerId, realName: this.tenantForm.referrerName}] : [];
    },
  },
  data () {
    return {
      tenantCompanyTypeEnumList: tenantCompanyTypeEnumList.filter(t=>!t.disabled),
      tenantForm: {
        companyName: '',
        certCode: '',
        scale: '',
        type: '',
        applicantName: '',
        applicantPhoneNumber: '',
        applicantPosition: '',
        trialProductId: '',
        trialProductName: '',
        referrerName: '',
        referrerId: '',
        referrerSource: 'bs',
      },
      tenantFormRules: {
        companyName: [
          { required: true, message: '请填写试用公司名称', trigger: 'blur'},
          { max: 35, message: '试用公司名称最大长度35字符', trigger: 'blur'},
        ],
        certCode: [
          { required: true, message: '请填写统一社会信用代码', trigger: 'blur'},
          { max: 30, message: '统一社会信用代码最大长度35字符', trigger: 'blur'},
        ],
        applicantName: [
          { required: true, message: '请填写联系人姓名', trigger: 'blur'},
          { max: 15, message: '联系人姓名最大长度15字符', trigger: 'blur'},
        ],
        applicantPhoneNumber: [
          { required: true, message: '请填写联系人手机号', trigger: 'blur'},
          { pattern: /^1\d{10}$/, message: '请输入正确的手机号码', trigger: 'blur'},
        ],
        applicantPosition: [
          { max: 15, message: '联系人职位最大长度15字符', trigger: 'blur'},
        ],
        trialProductId: [
          { required: true, message: '请选择试用产品', trigger: 'change'},
        ],
      },
      applyForm: {
        openType: '',
        trialPeriod: 7,
        adminAccount: '',
        adminPassword: '',
      },
      applyFormRules: {
        adminAccount: [
          { required: true, message: '超管账号为必填', trigger: 'blur'},
        ],
        adminPassword: [
          { required: true, message: '请输入超管密码', trigger: 'blur'},
          { max: 18, min: 6, message: '超管密码长度6~18', trigger: 'blur'},
        ],
      },
      rejectForm: {
        rejectReason: '',
      },
      dialogVisible: false,
      closeForm: {
        closeReason: '',
      },
      selectorVisible: false,
      isDisabled: false,
    };
  },
  watch: {
    dialogVisible () {
      if (!this.dialogVisible) {
        this.$refs.rejectForm && this.$refs.rejectForm.clearValidate();
      }
    },
  },
  methods: {
    handleConfirm (selected) {
      this.tenantForm.referrerId = selected[0] ? selected[0].id : '';
      this.tenantForm.referrerName = selected[0] ? selected[0].realName : '';
    },
    handleAddTenant () {
      this.$refs.tenantForm.validate(valid => {
        if (valid) {
          TenantApi.add({...this.tenantForm, name: this.tenantForm.companyName}).then(() => {
            this.$router.go(-1);
          });
        }
      });
    },
    handleReject () {
      this.$refs.rejectForm.validate(valid => {
        if (valid) {
          TenantApi.reject({id: this.id, status: this.tenantForm.trialStatus, ...this.rejectForm}).then(() => {
            this.dialogVisible = false;
            this.$router.go(-1);
          });
        }
      });
    },
    handleApproveTenant () {
      Promise.all([this.$refs.tenantForm.validate(), this.$refs.applyForm.validate()]).then(valids =>{
        if (valids.every(t=>t)) {
          this.$refs.applyForm.validate(valid => {
            if (valid) {
              TenantApi.approve({...this.tenantForm, ...this.applyForm, name: this.tenantForm.companyName}).then(() => {
                this.$router.go(-1);
              });
            }
          });
        }
      });
    },
    handleClose () {
      this.$refs.closeForm.validate(valid => {
        if (valid) {
          TenantApi.close({id: this.id, ...this.closeForm}).then(() => {
            this.$router.go(-1);
          });
        }
      });
    },
    handleCompanySelect (item) {
      this.tenantForm.certCode = item.CreditCode;
    },
    queryCompanyAsync (keyword, callback) {
      if (!keyword) {
        callback([]);
        return;
      }
      TenantApi.getCompanyInfo(keyword).then((res)=>{
        let result = res.body || [];
        if (result.length === 0) {
          this.$message.error('无相关公司信息');
        }
        callback(result);
      }, ()=>{
        callback([]);
      });
    },
    handleSearchCompany () {
      this.$refs.companySearch.focus();
    },
  },
  created () {
    if (this.id) {
      TenantApi.getDetail(this.id).then(res => {
        let companyName = res.body.name;
        this.tenantForm = {...res.body, companyName};
        this.isDisabled = !!res.body.channelId;
        if (this.tenantForm.trialStatus === 'waitActive') {
          let {openType, trialPeriod, adminAccount, adminPassword = '******'} = res.body;
          this.applyForm = {openType, trialPeriod, adminAccount, adminPassword};
          this.applyForm.openType = 'activate';
        } else if (this.tenantForm.trialStatus === 'wait') {
          this.applyForm.openType = 'trial';
          this.applyForm.adminAccount = this.tenantForm.applicantPhoneNumber;
        }
      });
    }
  },
  filters: {
    dateFormat: (val) =>{
      return dayjs(val).format('YYYY-MM-DD');
    },
  },
};
</script>
