<template>
  <content-block>
    <table-with-pagination :tableData="productList"
      :showRightToolbar="false"
      :columnList="columnList"
      :pagination.sync="pagination"
      :sortInfo.sync="updateSortInfo"
      @size-change="getTableList"
      @current-change="getTableList"
      @sort-change="getTableList"
    />
  </content-block>
</template>
<script>
import ProductApi from '@/api/product';
import { TablePaginationMixin } from '@/components/common/TableWithPagination';

export default {
  name: 'PRODUCT_INDEX',
  mixins: [
    TablePaginationMixin,
  ],
  data () {
    return {
      productList: [],
      columnList: [
        {
          tooltip: true,
          label: '产品名称',
          minWidth: '100px',
          prop: 'name',
        },
        {
          tooltip: true,
          label: '产品描述',
          minWidth: '100px',
          prop: 'description',
        },
        {
          tooltip: true,
          label: '上线时间',
          minWidth: '150px',
          prop: 'onlineTime',
          format: val=>this.$options.filters['date-format'](val),
        },
        {
          tooltip: true,
          label: '更新时间',
          minWidth: '150px',
          prop: 'lastUpdateTime',
          format: val=>this.$options.filters['date-format'](val),
        },
        {
          tooltip: true,
          label: '状态',
          minWidth: '100px',
          prop: 'releaseStatus',
          format: val => val === 'RELEASED' ? '已发布' : '已下线',
        },
        {
          tooltip: true,
          label: '操作',
          minWidth: '250px',
          fixed: 'right',
          prop: 'trialProductName',
          render: (h, {row})=>{
            let actions = [];
            if (row.releaseStatus === 'RELEASED') {
              actions.push(<span v-auth={'saas_productManage:off'} class='cursor-pointer text-primary' onClick={this.handleRelease.bind(this, row, 'DOWNLINE')}>下线</span>);
            } else {
              actions.push(<span v-auth={'saas_productManage:release'} class='cursor-pointer text-primary' onClick={this.handleRelease.bind(this, row, 'RELEASED')}>发布</span>);
            }
            actions.push(<span v-auth={'saas_cloudConfig:list'} class='cursor-pointer text-primary margin-left' onClick={this.handleActionClick.bind(this, row, 'PRODUCT_RESOURCE')}>功能管理</span>);
            actions.push(<span v-auth={'saas_functionManage:list'} class='cursor-pointer text-primary margin-left' onClick={this.handleActionClick.bind(this, row, 'PRODUCT_ORG_ROLE')}>云配置</span>);
            actions.push(<span v-auth={'saas_dataAuthority:list'} class='cursor-pointer text-primary margin-left' onClick={this.handleActionClick.bind(this, row, 'PRODUCT_DATASCOPE')}>数据权限设置</span>);
            return <div>{...actions}</div>;
          },
        },
      ],
    };
  },
  methods: {
    getTableList () {
      ProductApi.getProductList({...this.updateSortInfo, ...this.pagination, pageNum: this.pagination.currentPage}).then(res => {
        this.productList = res.body.list;
      });
    },
    handleActionClick (row, routerName) {
      this.$router.push({
        name: routerName,
        query: {
          id: row.id,
        },
      });
    },
    handleRelease (row, status) {
      let message = `确认${status !== 'DOWNLINE' ? '发布' : '下线'}[${row.name}]产品吗？`;
      let title = `确认${status !== 'DOWNLINE' ? '发布' : '下线'}`;
      this.$confirm(message, title, {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
      }).then(() => {
        ProductApi.updateReleaseStatus({productId: row.id, releaseStatus: status}).then(() => {
          this.handleSearch();
        });
      });
    },
    handleSearch () {
      this.pagination.currentPage = 1;
      this.getTableList();
    },
  },
};
</script>
