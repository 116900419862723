<template>
  <div>
    <table-with-pagination :tableData="accountList"
      :columnList="columnList"
      :showRightToolbar="false"
      :pagination.sync="pagination"
      @size-change="getTableList"
      @current-change="getTableList"
      @sort-change="getTableList"
      :leftToolbar="tableButtons"
    />
    <el-dialog :visible.sync="dialogVisible" title="密码重置" width="400px" :close-on-click-modal="false" @closed="handleDialogClose">
      <el-form ref="modifyPwdForm" :model="modifyPwdForm" :rules="rules" label-width="120px">
        <el-form-item label="新密码：" prop="newPwd">
          <el-input type="password" v-model.trim="modifyPwdForm.newPwd" placeholder="请输入新密码"></el-input>
        </el-form-item>
        <el-form-item label="确认密码：" prop="repeatNewPwd">
          <el-input type="password" v-model.trim="modifyPwdForm.repeatNewPwd" placeholder="请再次输入新密码"></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer">
        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="handleModifyPwd">确 定</el-button>
      </span>
    </el-dialog>
     <el-dialog :visible.sync="addAccountDialogVisible" title="新增超管账号" width="400px" :close-on-click-modal="false" @closed="handleNewDialogClose">
      <el-form ref="newAccountForm" :model="newAccountForm" :rules="newAccountFormRules" label-width="120px">
        <el-form-item label="手机号：" prop="phoneNumber">
          <el-input type="text" v-model.trim="newAccountForm.phoneNumber" placeholder="请输入手机号"></el-input>
        </el-form-item>
          <el-form-item label="姓名：" prop="name">
          <el-input type="text" v-model.trim="newAccountForm.name" placeholder="请输入姓名"></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer">
        <el-button @click="addAccountDialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="handleAddNewAccount">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>
<script>
import { TablePaginationMixin } from '@/components/common/TableWithPagination';
import TenantApi from '@/api/tenant';

export default {
  name: 'tenant_account_list',
  mixins: [
    TablePaginationMixin,
  ],
  props: {
    tenantId: String,
    tenantName: String,
  },
  data () {
    return {
      accountList: [],
      columnList: [
        {
          tooltip: true,
          label: '账号名称',
          minWidth: '100px',
          prop: 'phoneNumber',
        },
        {
          tooltip: true,
          label: '姓名',
          minWidth: '150px',
          prop: 'name',
        },
        {
          tooltip: true,
          label: '是否超管',
          minWidth: '100px',
          prop: 'adminFlag',
          format: val=>val ? '是' : '否',
        },
        {
          tooltip: true,
          label: '账号状态',
          minWidth: '150px',
          prop: 'accountStatusName',
        },
        {
          tooltip: true,
          label: '最后登录时间',
          minWidth: '100px',
          prop: 'lastLoginTime',
          format: this.$options.filters['date-format'],

        },
        {
          tooltip: true,
          label: '操作',
          minWidth: '150px',
          prop: 'action',
          render: (h, {row}) => {
            let actions = [];
            actions.push(<span class='cursor-pointer text-primary margin-left' onClick={this.handleActionClick.bind(this, row, 'resetPwd')}>重置密码</span>);
            if (!row.adminFlag) {
              actions.push(<span class='cursor-pointer text-primary margin-left' onClick={this.handleActionClick.bind(this, row, 'admin')}>变为超管</span>);
            }
            return <div>{...actions}</div>;
          },
        },
      ],
      tableButtons: [
        {
          name: '新增超管账号',
          status: 'primary',
          size: 'small',
          icon: 'vxe-icon--plus',
          click: ()=> {
            this.addAccountDialogVisible = true;
          },
        },
      ],
      dialogVisible: false,
      modifyPwdForm: {
        id: '',
        newPwd: '',
        repeatNewPwd: '',
      },
      rules: {
        newPwd: [
          { required: true, message: '请输入新密码', trigger: 'blur'},
          { min: 6, max: 11, message: '请输入6~11位字符', trigger: 'blur'},
        ],
        repeatNewPwd: [
          { required: true, message: '请再次输入新密码', trigger: 'blur'},
          { validator: (rule, value, cb) => { value !== this.modifyPwdForm.newPwd ? cb(new Error('两次密码输入不一致')) : cb(); }, trigger: 'blur'},
        ],
      },
      addAccountDialogVisible: false,
      newAccountForm: {
        name: '',
        phoneNumber: '',
      },
      newAccountFormRules: {
        name: [
          {
            required: true,
            message: '请输入姓名',
            trigger: ['change', 'blur'],
          },
          { max: 20, message: '姓名不可超过20字符', trigger: ['change', 'blur'] },
        ],
        phoneNumber: [
          {
            required: true,
            message: '请输入手机号',
            trigger: ['change', 'blur'],
          },
          { pattern: /^1\d{10}$/, message: '手机号不正确', trigger: ['change', 'blur'] },
        ],
      },
    };
  },
  methods: {
    handleActionClick (item, action) {
      if (action === 'resetPwd') {
        this.dialogVisible = true;
        this.modifyPwdForm.id = item.id;
      } else {
        this.$confirm('是否将该账号权限变更为超管', '提示').then(()=>{
          TenantApi.setAccountAsAdmin(item.id).then(()=>{
            this.$message.success('设置成功');
            this.getTableList();
          });
        });
      }
    },
    handleModifyPwd () {
      this.$refs.modifyPwdForm.validate(valid => {
        if (valid) {
          TenantApi.resetPwd(this.modifyPwdForm).then(() => {
            this.dialogVisible = false;
          });
        }
      });
    },
    handleDialogClose () {
      this.modifyPwdForm = {
        id: '',
        newPwd: '',
        repeatNewPwd: '',
      };
      this.$nextTick(()=>{
        this.$refs.modifyPwdForm.clearValidate();
      });
    },
    handleNewDialogClose () {
      this.newAccountForm = {
        name: '',
        phoneNumber: '',
      };
      this.$nextTick(()=>{
        this.$refs.newAccountForm.clearValidate();
      });
    },
    handleAddNewAccount () {
      this.$refs.newAccountForm.validate(valid=>{
        if (valid) {
          TenantApi.addAdminAccount({
            ...this.newAccountForm,
            tenantId: this.tenantId,
            tenantName: this.tenantName,
          }).then(()=>{
            this.addAccountDialogVisible = false;
            this.$message.success('新增成功');
            this.getTableList();
          });
        }
      });
    },
    getTableList () {
      let param = {tenantId: this.tenantId, pageSize: this.pagination.pageSize, pageNum: this.pagination.currentPage};
      TenantApi.tenantAccountList(param).then(res => {
        this.accountList = res.body.list;
        this.pagination.total = res.body.total;
      });
    },
  },
};
</script>
