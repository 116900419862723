<template>
<div>
  <content-block title="租户信息">
    <el-descriptions size="medium">
      <el-descriptions-item label="公司名称">{{tenatDetail.name || '--'}}</el-descriptions-item>
      <el-descriptions-item label="公司规模"> {{tenatDetail.scale || '--'}}</el-descriptions-item>
      <el-descriptions-item label="公司类型"> {{!!tenatDetail.type ? TenantCompanyTypeEnumList[TenantCompanyTypeEnum[tenatDetail.type]].desc : '--'}}</el-descriptions-item>
      <el-descriptions-item label="公司介绍" :span='3'>
        {{tenatDetail.remark ||'--'}}
      </el-descriptions-item>
      <el-descriptions-item label="试用申请人">{{tenatDetail.applicantName || '--'}}</el-descriptions-item>
      <el-descriptions-item label="申请人职位">{{tenatDetail.applicantPosition || '--'}}</el-descriptions-item>
      <el-descriptions-item label="试用申请人手机">{{tenatDetail.applicantPhoneNumber ||'--'}}</el-descriptions-item>
      <el-descriptions-item label="试用推荐人">{{tenatDetail.referrerName || '--'}}</el-descriptions-item>
      <el-descriptions-item label="最近登录时间"> {{tenatDetail.lastLoginTime | date-format}}</el-descriptions-item>
    </el-descriptions>
  </content-block>
  <content-block title="账号列表">
    <tenant-account-list :tenant-id="id"
      :tenant-name="tenatDetail.name"/>
  </content-block>
  <content-block title="激活记录">
    <table-with-pagination :tableData="payRecordList" :showRightToolbar="false" :columnList="columnList" :pagination.sync="pagination" @size-change="getTableList" @current-change="getTableList" />
    <el-button type="primary" plain @click="$router.go(-1)">返回</el-button>
  </content-block>
</div>
</template>

<script>
import TenantApi from '@/api/tenant';
import TenantAccountList from '../components/accountList.vue';
import {getOrderList} from '@/api/order';
import TenantSourceEnum from '@/model/TenantSourceEnum';
import TenantStatusEnum from '@/model/TenantStatusEnum';
import TenantCompanyTypeEnum, {TenantCompanyTypeEnumList} from '@/model/TenantCompanyTypeEnum';
import {TablePaginationMixin} from '@/components/common/TableWithPagination';

export default {
  name: 'TENANT_PURCHASE_DETAIL',
  mixins: [
    TablePaginationMixin,
  ],
  components: {
    TenantAccountList,
  },
  props: {
    id: {
      type: String,
    },
  },
  data () {
    return {
      TenantSourceEnum,
      TenantStatusEnum,
      TenantCompanyTypeEnum,
      TenantCompanyTypeEnumList,
      tenatDetail: {},
      payRecordList: [],
      columnList: [
        {
          tooltip: true,
          label: '购买时间',
          minWidth: '100px',
          prop: 'payTime',
          format: this.$options.filters['date-format'],
        },
        {
          tooltip: true,
          label: '租赁天数',
          minWidth: '100px',
          prop: 'leaseDays',
        },
        {
          tooltip: true,
          label: '到期时间',
          minWidth: '100px',
          prop: 'expiryTime',
          format: this.$options.filters['date-format'],
        },
        {
          tooltip: true,
          label: '关联订单号',
          minWidth: '100px',
          prop: 'code',
        },
      ],
    };
  },
  methods: {
    getTableList () {
      getOrderList({
        buyerId: this.id,
        pageSize: this.pagination.pageSize,
        pageNum: this.pagination.currentPage,
      }).then(res => {
        this.payRecordList = res.body.list;
        this.pagination.total = res.body.total;
      });
    },
  },
  created () {
    this.id && TenantApi.getDetail(this.id).then(res => {
      this.tenatDetail = res.body;
    });
  },
};
</script>
