<template>
    <el-popover
        popper-class="org_tree__popover"
        placement="bottom-start"
        trigger="manual"
        :visible-arrow="false"
        :width="width"
        v-clickoutside="handleClose"
        v-model="visible">
        <el-input ref="input" slot="reference" placeholder="请选择部门" :readonly="readonly" :value="departmentName" @focus="handleFocus"  :clearable="clearable" @clear="handleClear"></el-input>
        <div ref="departmentPopover">
          <el-input placeholder="输入关键字进行过滤" size="small"  v-model.trim="keyword"></el-input>
          <el-tree :data="departmentList"
              node-key="id"
              ref="departmentTree"
              :default-checked-keys="defaultCheckedKeys"
              :filter-node-method="filterNode"
              :props="{
                  label:(data)=>(data.name),
                  children:'children'
              }"
              @node-click="handleNodeClick">
          </el-tree>
        </div>
    </el-popover>
</template>
<script>
import ProductApi from '@/api/product';
import Clickoutside from 'element-ui/src/utils/clickoutside';
export default {
  name: 'department-selector',
  directives: { Clickoutside },
  props: {
    value: {
      type: String,
    },
    name: {
      type: String,
    },
    readonly: {
      type: Boolean,
      default: false,
    },
    clearable: {
      type: Boolean,
      default: true,
    },
    productId: {
      type: String,
      require: true,
    },
  },
  data () {
    return {
      visible: false,
      departmentList: [],
      departmentMap: {},
      width: 100,
      defaultCheckedKeys: [this.value],
      departmentName: this.name,
      keyword: '',
    };
  },
  watch: {
    keyword (val) {
      this.$refs.departmentTree.filter(val);
    },
    value () {
      this.getChianName(this.value);
    },
  },
  methods: {
    getDepartmentList () {
      return ProductApi.getOrgTree(this.productId);
    },
    filterNode (value, data) {
      if (!value) return true;
      return data.name.indexOf(value) !== -1;
    },
    handleFocus () {
      if (this.readonly) return;
      this.visible = !this.visible;
      if (this.visible) {
        this.initData();
      }
    },
    handleClear () {
      this.$emit('input', '');
      this.$emit('update:name', '');
      this.$emit('change', '');
      this.visible = false;
    },
    handleNodeClick (data) {
      this.getChianName(data.id);
      this.$emit('input', data.id);
      this.$emit('update:name', data.name);
      this.$emit('change', data);
      this.visible = false;
    },
    getChianName (nodeId) {
      let node = this.departmentMap[nodeId];
      this.departmentName = node ? node.name : '';
    },
    handleClose () {
      this.visible = false;
      this.keyword = '';
    },
    initData () {
      this.getDepartmentList().then(res => {
        this.departmentList = res.body || [];
        this.cacheNode(this.departmentList);
        this.value && this.$nextTick(() => {
          this.getChianName(this.value);
        });
      });
    },
    calcPopoverWidth () {
      let rect = this.$refs.input.$el.getBoundingClientRect();
      this.width = rect.width;
    },
    cacheNode (sources) {
      sources.forEach(c => {
        this.departmentMap[c.id] = c;
        if (c.children) {
          this.cacheNode(c.children);
        }
      });
    },
  },
  updated () {
    this.calcPopoverWidth();
  },
  mounted () {
    this.calcPopoverWidth();
    this.popperElm = this.$refs.departmentPopover;
    window.addEventListener('resize', this.calcPopoverWidth);
  },
  beforeDestroy () {
    window.removeEventListener('resize', this.calcPopoverWidth);
  },
};
</script>
<style lang="scss">
.org_tree__popover{
  max-height: 300px;
  overflow: auto;
}
</style>
