<template>
  <el-dialog
      :title="currentTitle"
      :visible.sync="isShow"
      width="70%"
      :append-to-body='true'
      :destroyOnClose='true'
      :close-on-click-modal='false'
    >
    <div class="content content_block" style="max-height: 600px;overflow: auto;">
      <ContentBlock title="申请信息："  >
        <el-form :model='JZInfo' class="noPadding" label-width='125px' ref="JZInfo"  inline label-position="right">
          <el-row >
            <el-col :span="8">
              <el-form-item label="申请单号：" prop="code" >
                {{JZInfo.code || '--'}}
              </el-form-item>
            </el-col>
            <el-col :span="8" >
              <el-form-item label="申请时间：" prop="createTime" >
                {{JZInfo.createTime || '--'}}
              </el-form-item>
            </el-col>
            <el-col :span="8">
              <el-form-item label="申请状态：" prop="statusDesc" >
                {{JZInfo.statusDesc || '--'}}
              </el-form-item>
            </el-col>
            <el-col :span="8">
              <el-form-item label="发票抬头：" prop="buyerName" >
                {{JZInfo.buyerName || '--'}}
              </el-form-item>
            </el-col>
            <el-col :span="8">
              <el-form-item label="税号：" prop="buyerTaxNumber" >
                {{JZInfo.buyerTaxNumber || '--'}}
              </el-form-item>
            </el-col>
            <el-col :span="8">
              <el-form-item label="开票总额：" prop="totalAmount" >
                {{JZInfo.totalAmount || '--'}}
              </el-form-item>
            </el-col>
            <el-col :span="8">
              <el-form-item label="发票类型：" prop="type" >
                {{JZInfo.typeDesc || '--'}}
              </el-form-item>
            </el-col>
            <!-- <el-col :span="8">
              <el-form-item label="开户行及账号：" prop="buyerBankAccount" >
                {{JZInfo.buyerBankAccount || '--'}}
              </el-form-item>
            </el-col> -->
            <el-col :span="8">
              <el-form-item label="开户行：" prop="buyerBank" >
                {{JZInfo.buyerBank || '--'}}
              </el-form-item>
            </el-col>
            <el-col :span="8">
              <el-form-item label="开户行账号：" prop="buyerAccount" >
                {{JZInfo.buyerAccount || '--'}}
              </el-form-item>
            </el-col>
            <!-- <el-col :span="8">
              <el-form-item label="地址、电话：" prop="buyerAddressMobile" >
                {{JZInfo.buyerAddressMobile || '--'}}
              </el-form-item>
            </el-col> -->
            <el-col :span="8">
              <el-form-item label="地址：" prop="buyerAddress" >
                {{JZInfo.buyerAddress || '--'}}
              </el-form-item>
            </el-col>
            <el-col :span="8">
              <el-form-item label="电话：" prop="buyerMobile" >
                {{JZInfo.buyerMobile || '--'}}
              </el-form-item>
            </el-col>
            <el-col :span="8">
              <el-form-item label="收票联系人：" prop="receiveName" >
                {{JZInfo.receiveName || '--'}}
              </el-form-item>
            </el-col>
            <el-col :span="8">
              <el-form-item label="联系电话：" prop="receivePhone" >
                {{JZInfo.receivePhone || '--'}}
              </el-form-item>
            </el-col>
            <el-col :span="8">
              <el-form-item label="开票方式：" prop="methodDesc" >
                {{JZInfo.methodDesc || '--'}}
              </el-form-item>
            </el-col>
          </el-row>
          <el-row v-if="isElectronic">
            <el-col :span="16">
              <el-form-item label="电子邮件：" prop="receiveMail" >
                {{JZInfo.receiveMail || '--'}}
              </el-form-item>
            </el-col>
          </el-row>
          <el-row v-else>
            <el-col :span="16">
              <el-form-item label="邮寄地址：" prop="receiveAddress" >
                {{JZInfo.receiveProvinceName}}{{JZInfo.receiveCityName}}{{JZInfo.receiveAreaName}}{{JZInfo.receiveAddress || '--'}}
              </el-form-item>
            </el-col>
          </el-row>
        </el-form>
      </ContentBlock>
      <!-- v-if='[4, 7].includes(stepActive) || status === 2' -->
      <ContentBlock title="开票信息：" >
        <el-form  label-width='110px' ref="JZInformation" :model='JZInformation' :rules='JZInformationRules'  inline label-position="right" :class="{'noPadding': status !== 2}">
          <el-row>
            <el-col :span='8'>
              <el-form-item label="发票代码：" prop="invoiceCode" >
                <el-input placeholder="请输入发票代码" v-if="status===2" v-model.trim="JZInformation.invoiceCode" maxlength='20' show-word-limit></el-input>
                <span v-else>{{JZInformation.invoiceCode || '--'}}</span>
              </el-form-item>
            </el-col>
            <el-col :span='8'>
              <el-form-item label="发票号码：" prop="invoiceNumber" >
                <el-input placeholder="请输入发票号码" v-if="status===2" v-model.trim="JZInformation.invoiceNumber" maxlength='20' show-word-limit></el-input>
                <span v-else>{{JZInformation.invoiceNumber || '--'}}</span>
              </el-form-item>
            </el-col>
            <el-col :span='8'>
              <el-form-item label="开票日期：" prop="drawTime" >
                <!-- :picker-options="pickerOptions" -->
                <el-date-picker
                  v-model.trim="JZInformation.drawTime"
                  type="date"
                  v-if="status===2"
                  value-format="yyyy-MM-dd"
                  placeholder="请选择开票日期">
                </el-date-picker>
                <span v-else>{{JZInformation.drawTime || '--'}}</span>
              </el-form-item>
            </el-col>
            <el-col :span='8'>
              <el-form-item label="开票人：" prop="drawUserName" >
                <el-input placeholder="请输入开票人" v-if="status===2" disabled v-model.trim="JZInformation.drawUserName" maxlength='20' show-word-limit></el-input>
                <span v-else>{{JZInformation.drawUserName || '--'}}</span>
              </el-form-item>
            </el-col>

            <el-col :span='16' v-if="isElectronic && stepActive > 2">
              <el-form-item label="发票附件："  v-if="status === 2" prop='fileList'>
                <Upload type='single' accept='application/pdf' :limit='1' :maxSize='5' label='发票附件' :isPdf='1' v-model="JZInformation.fileList">
                  <el-button size="mini" slot="trigger" type="default">上传</el-button>
                </Upload>
              </el-form-item>
              <el-form-item label="发票附件："  v-else>
                {{JZInformation.fileName || '--'}}
                <el-link type="primary" :underline="false" v-if="JZInformation.fileName" class="ML5" @click.native="handleView">查看</el-link>
                <el-link type="primary" :underline="false" v-if="JZInformation.fileName" class="ML5" @click.native="handleDownload">下载</el-link>
              </el-form-item>
            </el-col>
            <el-col :span='8' v-if="status === 3 || (!isElectronic && stepActive > 3)">
              <el-form-item label="快递公司：" prop="logisticsCompanyName" v-if="!isElectronic">
                <el-input placeholder="请输入快递公司" v-if="status!==3&&stepActive === 4" v-model.trim="JZInformation.logisticsCompanyName" maxlength='20' show-word-limit></el-input>
                <span v-else>{{JZInformation.logisticsCompanyName || '--'}}</span>
              </el-form-item>
            </el-col>
            <el-col :span='8' v-if="status === 3 || (!isElectronic && stepActive > 3)">
              <el-form-item label="物流单号：" prop="logisticsCode" v-if="!isElectronic">
                <el-input placeholder="请输入快递公司" v-if="status!==3&&stepActive === 4" v-model.trim="JZInformation.logisticsCode" maxlength='50' show-word-limit></el-input>
                <span v-else>{{JZInformation.logisticsCode || '--'}}</span>
              </el-form-item>
            </el-col>
          </el-row>
        </el-form>
      </ContentBlock>
      <ContentBlock title="产品明细：" >
        <TableWithPagination
          v-if="showAll"
          :tableData="targetLists"
          :columnList="columnList"
          maxHeight='300px'
          :showRightToolbar="false"
        ></TableWithPagination>
      </ContentBlock>
    </div>
    <SubmitResult ref="submitResult" @handleChangeInvoicing='handleChangeInvoicing'></SubmitResult>
    <span slot="footer" class="dialog-footer " style="text-align:right;" >
      <el-button @click="handleSave"  type='primary' size="mini" v-if="[3].includes(status) && stepActive === 2">确认</el-button>
      <el-button @click="handleChangeInvoicing" type='primary'  size="mini" v-if="[3].includes(status) && stepActive === 3">手动开票</el-button>
      <el-button @click="editInvoicingInfo" type='primary'  size="mini" v-if="[2, 4].includes(status)">保存</el-button>
      <el-button @click="handleSendEmail" type='primary'  size="mini" v-if="['1', '2'].includes(JZInfo.type) && [3].includes(status) && stepActive === 4">邮寄</el-button>
      <el-button @click="handleManualInvoicing" type='primary'  size="mini" v-if="status===3 && stepActive === 6">同步开票状态</el-button>
      <el-button @click="handleManualInvoicing" type='primary'  size="mini" v-if="status===3 && stepActive === 5">重新提交</el-button>
      <el-button @click="isShow=false" type='default' plain size="mini" >{{status === 3 ? '关闭' : '取消'}}</el-button>
    </span>
  </el-dialog>
</template>

<script>
import TableWithPagination, { TablePaginationMixin } from '@/components/common/TableWithPagination';
import {detailInvoice, confirmInvoice, drawInvoice, postInvoice, synJinShui, reSubmitJinShui} from '@/api/invoice';
import Upload from '@/components/common/upload';
import SubmitResult from './SubmitResult.vue';
import FileSaver from 'file-saver';
export default {
  name: 'ViewInvoicing',
  components: {
    TableWithPagination,
    Upload,
    SubmitResult,
  },
  mixins: [TablePaginationMixin],
  methods: {
    initPage () {
      this.isShow = true;
      this.isEditing = [2, 4].includes(this.status);
      this.showAll = true;
      this.getDetailInvoice();
      if (this.isEditing) {
        this.clearValidate('JZInformation');
      }
    },
    handleManualInvoicing () {
      let settings = {};
      Object.assign(settings, { // 合并对象
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'info',
        dangerouslyUseHTMLString: true, // 允许确认框内容为html格式
      });
      if (this.stepActive === 6) {
        this.$confirm('<p>确认同步申请开票状态吗？</p>', settings).then(() => {
          let currentParams = {
            id: this.currentInvoiceId,
          };
          synJinShui(currentParams).then((res) => {
            if (res.heads.code === 200) {
              this.$emit('handleSearch');
              this.isShow = false;
            } else if (res.heads.code === 6002) {
              this.$refs.submitResult.currentStatus = 2;
              this.$refs.submitResult.currentInvoiceId = this.currentInvoiceId;
              this.$refs.submitResult.isShow = true;
              this.$refs.submitResult.isViewing = true;
            }
          });
        });
      } else if (this.stepActive === 5) {
        this.$confirm('<p>确认重新提交申请吗？</p>', settings).then(() => {
          let currentParams = {
            id: this.currentInvoiceId,
          };
          reSubmitJinShui(currentParams).then((res) => {
            if (res.heads.code === 200) {
              this.handleSearch();
              this.isShow = false;
            } else if (res.heads.code === 6002) {
              this.$refs.submitResult.currentMessage = res.heads.message;
              this.$refs.submitResult.currentInvoiceId = this.currentInvoiceId;
              this.$refs.submitResult.currentStatus = 1;
              this.$refs.submitResult.isShow = true;
            }
          });
        });
      }
    },
    getDetailInvoice () {
      detailInvoice({id: this.currentInvoiceId}).then((res) => {
        let currentInfo = res.body;
        this.JZInfo = {
          buyerName: currentInfo.buyerName,
          buyerTaxNumber: currentInfo.buyerTaxNumber,
          totalAmount: currentInfo.totalAmount,
          type: currentInfo.type,
          buyerBankAccount: currentInfo.buyerBankAccount,
          buyerAccount: currentInfo.buyerAccount,
          buyerBank: currentInfo.buyerBank,
          buyerAddressMobile: currentInfo.buyerAddressMobile,
          buyerAddress: currentInfo.buyerAddress,
          buyerMobile: currentInfo.buyerMobile,
          receiveName: currentInfo.receiveName,
          receivePhone: currentInfo.receivePhone,
          receiveAddress: currentInfo.receiveAddress,
          receiveProvinceName: currentInfo.receiveProvinceName,
          receiveCityName: currentInfo.receiveCityName,
          receiveAreaName: currentInfo.receiveAreaName,
          receiveAreaId: currentInfo.receiveAreaId,
          receiveCityId: currentInfo.receiveCityId,
          receiveProvinceId: currentInfo.receiveProvinceId,
          createTime: currentInfo.createTime ? this.$options.filters['date-format'](currentInfo.createTime) : '--',
          typeDesc: currentInfo.typeDesc,
          statusDesc: currentInfo.statusDesc,
          code: currentInfo.code,
          receiveMail: currentInfo.receiveMail,
          methodDesc: currentInfo.methodDesc,
        };
        let drawTime = currentInfo.drawTime ? this.$options.filters['date-format'](currentInfo.drawTime, 'YYYY-MM-DD') : this.$options.filters['date-format'](Date.now(), 'YYYY-MM-DD');
        let drawUserName = currentInfo.drawUserName || this.$store.state.User.currentUserInfo.realName;
        if (this.status === 3) {
          drawTime = currentInfo.drawTime ? this.$options.filters['date-format'](currentInfo.drawTime, 'YYYY-MM-DD') : '--';
          drawUserName = currentInfo.drawUserName || '--';
        }
        this.JZInformation = {
          invoiceNumber: currentInfo.invoiceNumber,
          invoiceCode: currentInfo.invoiceCode,
          drawTime: drawTime,
          drawUserName: drawUserName,
          logisticsCompanyName: currentInfo.logisticsCompanyName,
          logisticsCode: currentInfo.logisticsCode,
          fileName: currentInfo.fileName || '',
          fileUrl: currentInfo.fileUrl || '',
          fileList: currentInfo.fileUrl ? [
            {
              url: currentInfo.fileUrl,
              fileName: currentInfo.fileName,
            },
          ] : [],
        };
        this.stepActive = currentInfo.status * 1 + 1;
        this.clearValidate('JZInformation');
        let itemList = currentInfo.itemList || [];
        itemList = itemList.map(item => {
          return {
            id: item.objId,
            code: item.objCode,
            objName: item.objName,
            channelName: item.channelName,
            totalAmount: item.amount,
            payTime: item.payTime,
            payMethodDesc: item.payMethodDesc,
          };
        });
        this.targetLists = itemList;
      });
    },
    handleChangeInvoicing () {
      this.$emit('handleSearch');
      this.status = 2;
      this.initPage();
    },
    handleSendEmail () {
      this.status = 4;
      this.initPage();
    },
    handleSave () {
      let settings = {};
      Object.assign(settings, { // 合并对象
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
        dangerouslyUseHTMLString: true, // 允许确认框内容为html格式
      });
      this.$confirm('<p>确认后申请方无法撤销修改，确定吗？</p>', settings).then(() => {
        let currentParams = {
          id: this.currentInvoiceId,
          ...this.JZInformation,
        };
        confirmInvoice(currentParams).then(() => {
          this.$emit('handleSearch');
          this.isShow = false;
        });
      });
    },
    editInvoicingInfo () {
      let fileList = this.JZInformation.fileList;
      this.$refs.JZInformation.validate(valid => {
        if (valid) {
          let settings = {};
          Object.assign(settings, { // 合并对象
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            type: 'warning',
            dangerouslyUseHTMLString: true, // 允许确认框内容为html格式
          });
          this.$confirm('<p>保存后不可修改，确定信息准确吗</p>', settings).then(() => {
            if (!this.isElectronic && this.stepActive === 4) {
              let currentParams = {
                id: this.currentInvoiceId,
                logisticsCompanyName: this.JZInformation.logisticsCompanyName,
                logisticsCode: this.JZInformation.logisticsCode,
              };
              postInvoice(currentParams).then(() => {
                this.$emit('handleSearch');
                this.isShow = false;
              });
            } else {
              let file = fileList[0];
              let currentParams = {
                ...this.JZInformation,
                id: this.currentInvoiceId,
                fileUrl: file ? file.url : '',
                fileName: file ? file.attachName : '',
                drawUserId: this.$store.state.User.currentUserInfo.loginUserId,
              };
              drawInvoice(currentParams).then(() => {
                this.$emit('handleSearch');
                this.isShow = false;
              });
            }
          });
        }
      });
    },
    clearValidate (name) {
      this.$nextTick(() => {
        if ([4, 7].includes(this.stepActive) || this.status === 2) {
          this.$refs[name].clearValidate();
        }
      });
    },
    // 查看
    handleView () {
      window.open(this.JZInformation.fileUrl);
    },
    // 下载
    handleDownload () {
      var oReq = new XMLHttpRequest();
      var URLToPDF = this.JZInformation.fileUrl;
      oReq.open('GET', URLToPDF, true);
      oReq.responseType = 'blob';
      oReq.onload = () => {
        var file = new Blob([oReq.response], {type: 'application/pdf'});
        FileSaver.saveAs(file, this.JZInformation.fileName);
      };
      oReq.send();
    },
  },
  data () {
    return {
      status: 1, // [1,2,3] = ['新增','编辑','查看']
      isShow: false,
      isEditing: false,
      showAll: false,
      currentInvoiceId: '',
      stepActive: 0,
      pickerOptions: {
        disabledDate: (time) => {
          return time.getTime() > Date.now();
        },
      },
      JZInfo: {
        buyerName: '',
        buyerTaxNumber: '',
        totalAmount: '',
        type: '',
        buyerBankAccount: '',
        buyerBank: '',
        buyerAccount: '',
        buyerAddressMobile: '',
        buyerAddress: '',
        buyerMobile: '',
        receiveName: '',
        receivePhone: '',
        receiveAddress: '',
        receiveProvinceId: '',
        receiveProvinceName: '',
        receiveCityId: '',
        receiveCityName: '',
        receiveAreaId: '',
        receiveAreaName: '',
        createTime: '',
        code: '',
        statusDesc: '',
        typeDesc: '',
        receiveMail: '',
      },
      JZInformation: {
        invoiceCode: '',
        invoiceNumber: '',
        drawTime: '',
        drawUserName: '',
        logisticsCompanyName: '',
        logisticsCode: '',
        fileList: [],
        fileName: '',
        fileUrl: '',
      },
      targetLists: [],
      columnList: [
        {
          tooltip: true,
          label: '订单编号',
          minWidth: '110px',
          prop: 'code',
        },
        {
          tooltip: true,
          label: '服务用户',
          minWidth: '160px',
          prop: 'objName',
        },
        {
          tooltip: true,
          label: '渠道名称',
          minWidth: '110px',
          prop: 'channelName',
        },
        {
          tooltip: true,
          label: '订单金额',
          minWidth: '110px',
          prop: 'totalAmount',
          format: val => {
            return parseFloat(val).toFixed(2);
          },
        },
        {
          tooltip: true,
          label: '支付时间',
          minWidth: '110px',
          prop: 'payTime',
          format: this.$options.filters['date-format'],
        },
        {
          tooltip: true,
          label: '支付方式',
          minWidth: '110px',
          prop: 'payMethodDesc',
        },
      ],
      invoicingTypeLists: [
        {
          label: '增值税普通发票',
          value: '1',
        },
        {
          label: '增值税专用发票',
          value: '2',
        },
      ],
    };
  },
  computed: {
    currentTitle () {
      let status = this.status;
      return status === 2 ? '维护开票信息' : '查看开票申请';
    },
    isElectronic () {
      return ['3', '4'].includes(this.JZInfo.type);
    },
    JZInformationRules () {
      let isElectronic = ['3', '4'].includes(this.JZInfo.type);
      return {
        invoiceCode: [
          {pattern: /^[0-9]*$/, message: '发票代码仅支持数字！', trigger: ['blur', 'change']},
        ],
        invoiceNumber: [
          {required: this.status !== 3, message: '发票号码必填！', trigger: ['blur', 'change']},
          {pattern: /^[0-9]*$/, message: '发票号码仅支持数字！', trigger: ['blur', 'change']},
        ],
        drawTime: [{required: this.status !== 3, message: '开票日期必填！', trigger: ['blur', 'change']}],
        logisticsCompanyName: [{required: this.status !== 3 && !isElectronic, message: '快递公司必填！', trigger: ['blur', 'change']}],
        logisticsCode: [{required: this.status !== 3 && !isElectronic, message: '物流单号必填！', trigger: ['blur', 'change']}],
        fileList: [{required: this.status === 2 && isElectronic, message: '发票附件不可为空！', trigger: ['blur', 'change']}],
      };
    },
  },
  watch: {
    targetLists (list) {
      let total = 0;
      if (list.length) {
        for (var i = 0; i < list.length; i++) {
          total += list[i].totalAmount;
        }
      }
      this.JZInfo.totalAmount = parseFloat(total).toFixed(2);
    },
  },
};
</script>
<style scoped lang='scss'>
/* @import url(); 引入css类 */
/deep/ .el-form-item--small.el-form-item{
  display: flex;
  .el-form-item__content{
    flex: 1;
    .el-date-editor.el-input{
      width: 100%;
    }
  }
}
/deep/ .content_title{
  display: flex;
  margin-bottom: 20px;
  &::before{
    width: 5px;
    height: 24px;
    content: '';
    background: #2577e3;
    margin-right: 10px;
  }
  h4{
    font-weight: normal;
    flex: 1;
  }
  .right{
    margin-right: 10px;
    display: flex;
    .show-more{
      flex: 1;
      margin-left: 10px;
      .icon{
        margin-left: 5px;
      }
    }
  }
}
/deep/ .blue{
  color: rgba(15, 137, 255, 0.7);
  cursor: pointer;
}
/deep/ .el-dialog__header{
  border-bottom: 1px solid #efefef;
}
/deep/ .el-dialog__footer{
  border-top: 1px solid #efefef;
}
/deep/ .noPadding .el-form-item--small.el-form-item{
  margin-bottom: 0;
}
/deep/ .attach_list.attach_list--normal .el-upload-list__item {
  width: auto;
  height: auto;
}
/deep/ .ML5{
  margin-left: 5px;
}
</style>
