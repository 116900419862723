<template>
<div>
  <content-block>
    <content-title slot="title" justify="space-between">
      <span>试用信息</span>
      <span>{{tenatDetail.tenant}}</span>
    </content-title>
    <el-descriptions size="medium">
      <el-descriptions-item label="公司名称">
        {{tenatDetail.name}}
      </el-descriptions-item>
      <el-descriptions-item label="统一社会信用代码">
        {{tenatDetail.certCode}}
      </el-descriptions-item>
      <el-descriptions-item label="公司规模">
        {{tenatDetail.scale || '--'}}
      </el-descriptions-item>
      <el-descriptions-item label="公司类型">
        {{!!tenatDetail.type ? TenantCompanyTypeEnumList[TenantCompanyTypeEnum[tenatDetail.type]].desc : '--'}}
      </el-descriptions-item>
      <el-descriptions-item label="申请人">
        {{tenatDetail.applicantName || '--'}}
      </el-descriptions-item>
      <el-descriptions-item label="申请人手机">
        {{tenatDetail.applicantPhoneNumber ||'--'}}
      </el-descriptions-item>
      <el-descriptions-item label="公司介绍" :span='3'>
        {{tenatDetail.remark ||'--'}}
      </el-descriptions-item>
      <el-descriptions-item label="申请时间">
        {{tenatDetail.createTime | date-format}}
      </el-descriptions-item>
      <el-descriptions-item label="申请人职位">
        {{tenatDetail.applicantPosition || '--'}}
      </el-descriptions-item>
      <el-descriptions-item label="试用产品">
        {{tenatDetail.trialProductName || '--'}}
      </el-descriptions-item>
      <el-descriptions-item label="试用状态">
        {{tenatDetail.trialStatus ? TenantStatusEnumList[TenantStatusEnum[tenatDetail.trialStatus]].desc : '--'}}
      </el-descriptions-item>
      <el-descriptions-item label="最近登录时间">
        {{tenatDetail.lastLoginTime | date-format}}
      </el-descriptions-item>
      <el-descriptions-item label="首次开通日期">
        {{tenatDetail.firstOpenTime | formatDate}}
      </el-descriptions-item>
      <el-descriptions-item label="试用到期日期">
        {{tenatDetail.trialExpiryTime | formatDate}}
      </el-descriptions-item>
      <el-descriptions-item label="推荐人来源">
        {{tenatDetail.referrerSource || '--'}}
      </el-descriptions-item>
      <el-descriptions-item label="推广渠道">
        {{tenatDetail.channelName || '--'}}
      </el-descriptions-item>
      <el-descriptions-item label="推荐人">
        {{tenatDetail.referrerName || '--'}}
      </el-descriptions-item>
      <el-descriptions-item label="推广渠道">
        {{tenatDetail.channelName || '--'}}
      </el-descriptions-item>
      <el-descriptions-item label="销售来源">
        {{tenatDetail.salesSource ? TenantSourceEnumList[TenantSourceEnum[tenatDetail.salesSource]].desc : '--'}}
      </el-descriptions-item>
      <el-descriptions-item :span="3" v-if="tenatDetail.trialStatus==='close'" label="关闭原因">
        {{tenatDetail.closeReason || '--'}}
      </el-descriptions-item>
    </el-descriptions>
  </content-block>
  <content-block title="账号列表">
    <tenant-account-list :tenant-id="id"
      :tenant-name="tenatDetail.name"/>
    <el-button @click="$router.go(-1)" plain type="primary">返回</el-button>
  </content-block>

</div>
</template>
<script>
import TenantApi from '@/api/tenant';
import TenantAccountList from './components/accountList.vue';
import * as dayjs from 'dayjs';
import TenantSourceEnum, { TenantSourceEnumList } from '@/model/TenantSourceEnum';
import TenantStatusEnum, { TenantStatusEnumList } from '@/model/TenantStatusEnum';
import TenantCompanyTypeEnum, { TenantCompanyTypeEnumList } from '@/model/TenantCompanyTypeEnum';
export default {
  name: 'TENANT_DETAIL',
  components: {
    TenantAccountList,
  },
  props: {
    id: {
      type: String,
    },
  },
  data () {
    return {
      TenantSourceEnum,
      TenantSourceEnumList,
      TenantStatusEnum,
      TenantStatusEnumList,
      TenantCompanyTypeEnum,
      TenantCompanyTypeEnumList,
      tenatDetail: {},
    };
  },
  created () {
    this.id && TenantApi.getDetail(this.id).then(res => {
      let currentInfo = res.body;
      currentInfo.referrerSource = currentInfo.referrerSource === 'bs' ? '后台' : currentInfo.referrerSource === 'pc' ? 'PC端' : currentInfo.referrerSource === 'h5' ? 'H5' : '--';
      this.tenatDetail = currentInfo;
    });
  },
  filters: {
    formatDate: (val) =>{
      return val ? dayjs(val).format('YYYY-MM-DD') : '';
    },
  },
};
</script>
