<template>
  <div>
    <query-form labelWidth="110px" class="content_block" ref="queryForm">
      <el-form-item label="申请单号：" >
        <el-input placeholder="请输入申请单号" v-model.trim="query.code"></el-input>
      </el-form-item>
      <el-form-item label="发票抬头：" >
        <el-input placeholder="请输入发票抬头" v-model.trim="query.buyerName"></el-input>
      </el-form-item>
      <el-form-item label="发票号码：" >
        <el-input placeholder="请输入发票号码" v-model.trim="query.invoiceNumber"></el-input>
      </el-form-item>
      <el-form-item label="申请状态：" >
        <el-select v-model="query.status" @change="handleSearch">
          <el-option v-for="i in statusList" :key="i.value" :label="i.name" :value="i.value"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="申请时间(起)：" prop="createStartTime" class="JZCategoryFormType">
        <span slot="label" title="最后操作开始时间">
          申请时间(起)：
        </span>
        <el-date-picker @change='handleSearch' type="datetime"  placeholder="请选择申请时间(起)" value-format="timestamp"  v-model.trim="query.createStartTime"></el-date-picker>
      </el-form-item>
      <el-form-item label="申请时间(止)：" prop="createEndTime" class="JZCategoryFormType">
          <span slot="label" title="最后操作结束时间">
          申请时间(止)：
        </span>
        <el-date-picker @change='handleSearch' type="datetime"  placeholder="请选择申请时间(止)" value-format="timestamp"  v-model.trim="query.createEndTime"></el-date-picker>
      </el-form-item>
      <el-button slot="action" type='primary' size='mini' @click.native='handleSearch'>查询</el-button>
      <el-button slot="action" type='default' size='mini' @click.native='handleReset'>重置</el-button>
    </query-form>
    <table-with-pagination :tableData="orderList"
      class="content_block"
      :showRightToolbar="false"
      :columnList="columnList"
      :pagination.sync="pagination"
      @size-change="getTableList"
      @current-change="getTableList"
    ></table-with-pagination>
    <ViewInvoicing ref="viewInvoicing" @handleSearch='handleSearch'></ViewInvoicing>
    <SubmitResult ref="submitResult" @handleManualInvoicing='handleManualInvoicing'  ></SubmitResult>
    <el-dialog title="驳回" width="460px" :visible.sync="updateRejectVisible" :close-on-click-modal="false">
      <el-form :model="ruleForm" :rules="rules" ref="ruleForm">
        <el-form-item label="驳回原因: " prop="reason">
          <el-input type="textarea" :rows="4"
            placeholder="请输入驳回原因"
            style="width: 300px;"
            v-model="ruleForm.reason">
          </el-input>
        </el-form-item>
      </el-form>
      <span slot="footer">
        <el-button @click="updateRejectVisible = false">取消</el-button>
        <el-button type="primary" @click="handleReject">确认</el-button>
      </span>
    </el-dialog>
  </div>
</template>
<script>
import { getInvoiceLists, confirmInvoice, reSubmitJinShui, synJinShui, rejectInvoice} from '@/api/invoice';
import { TablePaginationMixin } from '@/components/common/TableWithPagination';
import ViewInvoicing from './components/ViewInvoicing.vue';
import SubmitResult from './components/SubmitResult.vue';
export default {
  name: 'ORDER_INDEX',
  mixins: [
    TablePaginationMixin,
  ],
  components: {
    ViewInvoicing,
    SubmitResult,
  },
  created () {
    this.handleSearch();
  },
  methods: {
    handleSearch () {
      this.pagination.currentPage = 1;
      this.getTableList();
    },
    handleReset () {
      this.query = {
        code: '',
        buyerName: '',
        invoiceNumber: '',
        status: '',
        createStartTime: '',
        createEndTime: '',
      };
      this.handleSearch();
    },
    getTableList () {
      let param = {
        ...this.query,
        pageSize: this.pagination.pageSize,
        pageNum: this.pagination.currentPage,
      };
      param.orderBy = [{
        orderByProperty: 'createTime',
        orderByType: 'ASC',
      }];
      getInvoiceLists(param).then(res => {
        this.orderList = res.body.list;
        this.pagination.total = res.body.total;
      });
    },
    handleActionClick (info, status) {
      let settings = {};
      Object.assign(settings, { // 合并对象
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'info',
        dangerouslyUseHTMLString: true, // 允许确认框内容为html格式
      });
      if (status === 1) {
        this.$confirm('<p>确认后申请方无法撤销修改，确定吗？</p>', settings).then(() => {
          let currentParams = {
            id: info.id,
          };
          confirmInvoice(currentParams).then(() => {
            this.handleSearch();
          });
        });
      } else if (status === 7) {
        // 驳回
        this.currentOrder = info;
        this.updateRejectVisible = true;
      } else if (status === 5) {
        this.$confirm('<p>确认重新提交申请吗？</p>', settings).then(() => {
          let currentParams = {
            id: info.id,
          };
          reSubmitJinShui(currentParams).then((res) => {
            if (res.heads.code === 200) {
              this.handleSearch();
            } else if (res.heads.code === 6002) {
              this.currentOrder = info;
              this.$refs.submitResult.currentMessage = res.heads.message;
              this.$refs.submitResult.currentStatus = 1;
              this.$refs.submitResult.currentInvoiceId = info.id;
              this.$refs.submitResult.isViewing = false;
              this.$refs.submitResult.isShow = true;
            }
          });
        });
      } else if (status === 6) {
        this.$confirm('<p>确认同步申请开票状态吗？</p>', settings).then(() => {
          let currentParams = {
            id: info.id,
          };
          synJinShui(currentParams).then((res) => {
            if (res.heads.code === 200) {
              this.handleSearch();
            } else if (res.heads.code === 6002) {
              this.currentOrder = info;
              this.$refs.submitResult.currentInvoiceId = info.id;
              this.$refs.submitResult.currentStatus = 2;
              this.$refs.submitResult.isViewing = false;
              this.$refs.submitResult.isShow = true;
            }
          });
        });
      } else {
        this.$refs.viewInvoicing.status = status;
        this.$refs.viewInvoicing.currentInvoiceId = info.id;
        this.$refs.viewInvoicing.initPage();
      }
    },
    interfaceConfiguration () {
      this.$refs.interfaceConfiguration.initPage();
    },
    handleManualInvoicing () {
      this.getTableList();
      this.$refs.viewInvoicing.status = 2;
      this.$refs.viewInvoicing.currentInvoiceId = this.currentOrder.id;
      this.$refs.viewInvoicing.initPage();
    },
    handleReject () {
      // console.log('调用接口,驳回原因：' + this.ruleForm.reason);
      if (!this.ruleForm.reason) {
        this.$message.warning({
          message: '请先填写驳回原因',
        });
        return;
      }
      // 调用接口
      rejectInvoice({ id: this.currentOrder.id, rejectReason: this.ruleForm.reason }).then((res) => {
        if (res.heads.code === 200) {
          this.handleSearch();
          this.updateRejectVisible = false;
          this.ruleForm.reason = '';
        }
      });
    },
  },
  data () {
    return {
      query: {
        code: '',
        buyerName: '',
        invoiceNumber: '',
        status: '',
        createStartTime: '',
        createEndTime: '',
      },
      ruleForm: {
        reason: '',
      },
      rules: {
        reason: [
          { required: true, message: '请填写驳回原因', trigger: 'blur' },
        ],
      },
      updateRejectVisible: false,
      currentOrder: {},
      currentTitle: '',
      isShow: false,
      currentStatus: 1,
      orderList: [],
      statusList: [
        {
          name: '全部',
          value: '',
        },
        {
          name: '待确认',
          value: '1',
        },
        {
          name: '已确认',
          value: '2',
        },
        {
          name: '已开票',
          value: '3',
        },
        {
          name: '提交失败',
          value: '4',
        },
        {
          name: '金税开票中',
          value: '5',
        },
        {
          name: '已寄出',
          value: '6',
        },
        {
          name: '已驳回',
          value: '7',
        },
      ],
      columnList: [
        {
          tooltip: true,
          label: '申请单号',
          minWidth: '180px',
          prop: 'code',
          fixed: 'left',
          render: (h, {row}) => {
            let queryStatus = this.$store.state.Auth.actionPermissionMap['saas_invoicApply:details'] || false;
            if (queryStatus) {
              return <span class='cursor-pointer text-primary' onClick={this.handleActionClick.bind(this, row, 3)}>{row.code}</span>;
            } else {
              return <span>{row.code || '--'}</span>;
            }
          },
        },
        {
          tooltip: true,
          label: '申请时间',
          minWidth: '150px',
          prop: 'createTime',
          format: val => this.$options.filters['date-format'](val),
        },
        {
          tooltip: true,
          label: '申请状态',
          minWidth: '100px',
          prop: 'statusDesc',
        },
        {
          tooltip: true,
          label: '开票方式',
          minWidth: '150px',
          prop: 'methodDesc',
        },
        {
          tooltip: true,
          label: '发票抬头',
          minWidth: '150px',
          prop: 'buyerName',
        },
        {
          tooltip: true,
          label: '税号',
          minWidth: '150px',
          prop: 'buyerTaxNumber',
        },
        {
          tooltip: true,
          label: '开票总额',
          minWidth: '120px',
          prop: 'totalAmount',
          format: val => {
            return parseFloat(val).toFixed(2);
          },
        },
        {
          tooltip: true,
          label: '发票类型',
          minWidth: '150px',
          prop: 'typeDesc',
        },
        {
          tooltip: true,
          label: '开户行及账号',
          minWidth: '180px',
          prop: 'buyerBankAccount',
          render: (h, {row}) => {
            return <span>{row.buyerBank + row.buyerAccount || '--'}</span>;
          },
        },
        {
          tooltip: true,
          label: '地址、电话',
          minWidth: '180px',
          prop: 'buyerAddressMobile',
          render: (h, {row}) => {
            return <span>{row.buyerAddress + row.buyerMobile || '--'}</span>;
          },
        },
        {
          tooltip: true,
          label: '收票联系人',
          minWidth: '180px',
          prop: 'receiveName',
        },
        {
          tooltip: true,
          label: '联系电话',
          minWidth: '150px',
          prop: 'receivePhone',
        },
        {
          tooltip: true,
          label: '邮寄地址',
          minWidth: '180px',
          prop: 'receiveAddress',
        },
        {
          tooltip: true,
          label: '发票代码',
          minWidth: '180px',
          prop: 'invoiceCode',
        },
        {
          tooltip: true,
          label: '发票号码',
          minWidth: '180px',
          prop: 'invoiceNumber',
        },
        {
          tooltip: true,
          label: '开票日期',
          minWidth: '110px',
          prop: 'drawTime',
          format: val=>this.$options.filters['date-format'](val, 'YYYY-MM-DD'),
        },
        {
          tooltip: true,
          label: '开票人',
          minWidth: '110px',
          prop: 'drawUserName',
        },
        {
          tooltip: true,
          label: '驳回原因',
          minWidth: '110px',
          prop: 'rejectReason',
        },
        {
          label: '操作',
          minWidth: '110px',
          fixed: 'right',
          render: (h, {row}) => {
            let actions = [];
            if (row.status === '1') {
              actions.push(<span class='cursor-pointer text-primary' v-auth={'saas_invoicApply:confirm'} onClick={this.handleActionClick.bind(this, row, 1)}>确认</span>);
              actions.push(<span>  </span>);
              actions.push(<span class='cursor-pointer text-primary' v-auth={'saas_invoicApply:confirm'} onClick={this.handleActionClick.bind(this, row, 7)}>驳回</span>);
            } else if (row.status === '2') {
              actions.push(<span class='cursor-pointer text-primary' v-auth={'saas_invoicApply:invoic'} onClick={this.handleActionClick.bind(this, row, 2)}>手工开票</span>);
            } else if (['1', '2'].includes(row.type) && row.status === '3') {
              actions.push(<span class='cursor-pointer text-primary' v-auth={'saas_invoicApply:invoic'} onClick={this.handleActionClick.bind(this, row, 4)}>邮寄</span>);
            } else if (row.status === '4') {
              actions.push(<span class='cursor-pointer text-primary' v-auth={'saas_invoicApply:invoic'} onClick={this.handleActionClick.bind(this, row, 5)}>重新提交</span>);
            } else if (row.status === '5') {
              actions.push(<span class='cursor-pointer text-primary' v-auth={'saas_invoicApply:invoic'} onClick={this.handleActionClick.bind(this, row, 6)}>同步开票状态</span>);
            }
            return <div>{...actions}</div>;
          },
        },
      ],
    };
  },
};
</script>
<style lang="scss" scoped>
.rejectContent {
  margin-left: 20px;
  display: flex;
  justify-content: space-evenly;
}
/deep/ .el-date-editor.el-input {
  width: 100%;
}
/deep/ .el-dialog__header{
  border-bottom: 1px solid #efefef;
}
/deep/ .el-dialog__footer{
  border-top: 1px solid #efefef;
}
</style>

